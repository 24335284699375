import { AiOutlineFileText } from "react-icons/ai";
import { BsChat } from "react-icons/bs";
import { CgShoppingCart } from "react-icons/cg";
import { FiBox } from "react-icons/fi";

import { useCart, useUnreadChats } from "../../hooks";
import BottomTab from "./BottomTab";
import CountBadge from "../CountBadge";

const BottomNav = () => {
  const { unreadCount } = useUnreadChats();
  const cart = useCart();

  return (
    <div className="btm-nav md:hidden">
      <BottomTab Icon={<FiBox size={24} />} label="Products" pathname="/mart" />

      <BottomTab
        Icon={<CgShoppingCart size={24} />}
        children={<CountBadge count={cart.count} right={-10} top={-1} />}
        label="Cart"
        pathname="/mart/cart"
      />

      <BottomTab
        Icon={<AiOutlineFileText size={24} />}
        label="Orders"
        pathname="/mart/orders"
      />

      <BottomTab
        children={<CountBadge count={unreadCount} right={-10} top={-1} />}
        Icon={<BsChat size={20} />}
        label="Chats"
        pathname="/chats"
      />
    </div>
  );
};

export default BottomNav;
