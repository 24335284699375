import { useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { DefaultGenerics, StreamClient } from "getstream";
import { toast } from "react-toastify";

import { authTokenKey, processResponse } from "../services/client";
import {
  createAndGetChatToken,
  createOrGetFeedToken,
} from "../services/tokens";
import { UserContext } from "../contexts";
import accounts from "../services/accounts";
import auth, { googleAuth, GoogleUser } from "../services/auth";
import usersApi from "../services/users";

export interface OtherAccounts {
  instagram?: string;
  twitter?: string;
  whatsapp?: string;
  youtube?: string;
}

export interface User {
  _id: string;
  aboutMe?: string;
  avatar?: string;
  chatToken?: string;
  feedToken?: string;
  email: string;
  chatIds?: { [email: string]: string };
  isAdmin: boolean;
  isVerified: boolean;
  name: string;
  otherAccounts?: OtherAccounts;
  pushTokens?: { [token: string]: string };
  timestamp: number;
}

export default (): {
  user: User | undefined;
  googleUser: null | GoogleUser | undefined;
  // loginWithGoogle: () => Promise<UserCredential>;
  setUser: (user: User) => void;
} => {
  const context = useContext(UserContext);
  const [googleUser] = useAuthState(googleAuth);

  // const loginWithGoogle = async () =>
  //   await signInWithPopup(googleAuth, new GoogleAuthProvider());

  return { ...context, googleUser };
};

export async function initUser({
  googleUser,
  setUser,
  user,
}: {
  user: User | undefined;
  setUser: (user: User) => void;
  googleUser: GoogleUser | null | undefined;
}) {
  if (user) return;
  const storedUser = auth.getCurrentUserFromStorage();
  if (storedUser) return setUser(storedUser);

  if (!googleUser) return;
  const { email, displayName, photoURL } = googleUser;
  if (!email || !displayName || !photoURL) return;

  const res = await usersApi.quickAuth({
    email,
    name: displayName,
    avatar: photoURL,
  });

  const { data, ok } = processResponse(res);
  if (ok) {
    auth.loginWithJwt(res.headers[authTokenKey]);
    setUser(data as User);
    window.location.reload();
  } else toast.error("Login failed");
}

export async function checkUserFeedToken(
  user: User,
  setUser: (user: User) => void
) {
  if (!user || user.feedToken) return;

  const res = await createOrGetFeedToken();
  if (res?.ok) {
    setUser({ ...user, feedToken: res.data as string });
  } else {
    toast.error(res.problem);
    auth.logout();
  }
}

export async function checkUserChatToken(
  user: User,
  setUser: (user: User) => void
) {
  if (!user || user.chatToken) return;

  const res = await createAndGetChatToken();
  if (res?.ok) setUser({ ...user, chatToken: res.data as string });
  else {
    toast.error(res.problem);
    auth.logout();
  }
}

export async function updateUserInfo(
  user: User,
  feedClient: StreamClient<DefaultGenerics> | undefined
) {
  if (!user || accounts.getAcitveAccount()) return;

  const { _id, email, name, avatar } = user;
  if (feedClient?.currentUser?.data?.name === "Unknown")
    await feedClient?.currentUser?.update({
      _id,
      email,
      name,
      avatar,
    });
}
