import { useEffect, useState } from "react";
import { useStreamContext } from "react-activity-feed";

import useNotifications from "./useNotifications";

const useSubscription = ({ shopId }: { shopId: string }) => {
  const { client } = useStreamContext();
  const [isSubscribing, setIsSubscribing] = useState(false);
  const { createNotification } = useNotifications();

  useEffect(() => {
    async function init() {
      try {
        const response = await client
          ?.feed("timeline", client.userId)
          .following({ filter: [`user:${shopId}`] });

        setIsSubscribing(Boolean(response?.results.length));
      } catch (error) {}
    }

    init();
  }, [shopId, client]);

  const toggleSubscription = async () => {
    const action = isSubscribing ? "unfollow" : "follow";

    if (action === "follow") createNotification(shopId, action);

    const timelineFeed = client?.feed("timeline", client.userId);
    await timelineFeed?.[action]("user", shopId);

    setIsSubscribing((value) => !value);
  };

  return { isSubscribing, toggleSubscription };
};

export default useSubscription;
