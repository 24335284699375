import client, { getFailedResponse, processResponse } from "./client";

const endpoint = "/messaging";

interface SMS {
  text: string;
  to: string;
}

export const sendSMS = async (sms: SMS) => {
  try {
    return processResponse(await client.post(endpoint, sms));
  } catch (error) {
    return getFailedResponse(error);
  }
};
