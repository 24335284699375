import { useParams } from "react-router-dom";

import { Grid, ShopProfile } from "..";
import { useShops } from "../../hooks";

const ProfileShops = () => {
  const { userId } = useParams();
  const { shops } = useShops();

  const userShops = shops.filter((shop) => shop.author?._id === userId);

  return (
    <div>
      {Boolean(userShops.length) && (
        <section className="px-10 py-5 ">
          <Grid>
            {userShops.map((shop) => (
              <ShopProfile {...shop} key={shop._id} />
            ))}
          </Grid>
        </section>
      )}
    </div>
  );
};

export default ProfileShops;
