import { useEffect, useState } from "react";
import { Gallery } from "react-activity-feed";
import { toast } from "react-toastify";
import { BsPlus } from "react-icons/bs";

import { events, logEvent } from "../db/analytics";
import { paginate } from "../utils";
import { Pagination } from "../components";
import { useImages, useUser } from "../hooks";
import ImageInputList from "../components/common/ImageInputList";
import service from "../services/posters";
import storage from "../db/image";
import usePosters, { Poster } from "../hooks/usePosters";

const MAX_IMAGES_INPUT = 1;

const EventsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(6);
  const { addPoster } = usePosters();
  const [posters, setPosters] = useState<Poster[]>([]);
  const { imagesCount, removeAllImages, images } = useImages(MAX_IMAGES_INPUT);
  const [startTakingPics, setStartTakingPics] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    const initPosters = async () => {
      const res = await service.getPosters();
      if (res.ok) setPosters(res.data as Poster[]);
    };

    initPosters();

    logEvent(events.general.PAGE_VIEW, {
      userId: user?._id,
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  const paginated = paginate<Poster>(posters, currentPage, pageSize);

  const cancelTakingPics = () => {
    removeAllImages();
    setStartTakingPics(false);
  };

  const savePoster = async () => {
    if (!user) return toast.info("Login to post your poster");

    try {
      const posterUrl = await storage.saveImage(images[0]);
      const res = await service.savePoster({ image: posterUrl });

      if (res.ok) {
        toast.success("Poster saved successfully");
        addPoster(res.data as Poster);
      } else toast.error("Something failed");
    } catch (error) {
      toast.error("Something failed");
    }
  };

  return (
    <section>
      <div className="min-h-screen py-10 px-4 pt-0">
        <div className="flex flex-col md:flex-row w-full justify-center items-center px-3 my-6 mx-auto space-y-3 md:space-y-0 md:space-x-4">
          <h1 className="text-4xl font-bold text-center">Posters</h1>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => setStartTakingPics(true)}
          >
            <BsPlus />
            Add Poster
          </button>
        </div>
        {startTakingPics && (
          <>
            <ImageInputList imagesLimit={MAX_IMAGES_INPUT} />
            <button
              className={`btn btn-success btn-sm mr-3 ${
                imagesCount ? "" : "btn-disabled"
              }`}
              onClick={savePoster}
            >
              Save Poster
            </button>
            <button className="btn btn-error btn-sm" onClick={cancelTakingPics}>
              Cancel
            </button>
          </>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {paginated.map((poster, index) => (
            <div
              key={index}
              className="card card-compact bg-base-100 shadow-xl overflow-hidden"
            >
              <figure>
                <Gallery
                  className="w-full h-48 object-cover"
                  images={[poster.image]}
                />
              </figure>
            </div>
          ))}
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        itemsCount={paginated.length}
        pageSize={pageSize}
      />
    </section>
  );
};

export default EventsPage;
