export interface Tab<T> {
  active?: boolean;
  onClick?: () => void;
  tabName: T;
  tabLabel: string;
}

function Tab<T>({ active, tabName, tabLabel, ...otherProps }: Tab<T>) {
  return (
    <a
      role="tab"
      className={`tab ${active ? "tab-active" : ""}`}
      {...otherProps}
    >
      {tabLabel}
    </a>
  );
}

interface Props<T> {
  activeTab: T;
  onTabClick: (tabName: T) => void;
  tabs: Tab<T>[];
}

export default function Tabs<T>({ tabs, onTabClick, activeTab }: Props<T>) {
  return (
    <div role="tablist" className="tabs tabs-bordered mb-4">
      {tabs.map((tab, index) => (
        <Tab
          {...tab}
          key={index}
          active={activeTab === tab.tabName}
          onClick={() => onTabClick(tab.tabName)}
        />
      ))}
    </div>
  );
}
