import client, { getFailedResponse, processResponse } from "./client";

const endpoint = "/queries";

export type NewQuery = {
  userId?: string;
  text: string;
};

export type Query = {
  _id: string;
  text: string;
  timestamp: number;
  userId: string;
};

const create = async (query: NewQuery) => {
  try {
    return processResponse(await client.post(endpoint, query));
  } catch (error) {
    return getFailedResponse(error);
  }
};

const getAll = async () => {
  try {
    return processResponse(await client.get(endpoint));
  } catch (error) {
    return getFailedResponse(error);
  }
};

export default { create, getAll };
