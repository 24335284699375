import { useState } from "react";

import AdminsContacts from "./AdminsContacts";
import Modal from "./Modal";

const AdminsModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div className="flex items-center justify-between p-4 bg-gray-100 rounded-lg">
      <p className="text-gray-700">
        Can't find what you're looking for? Contact us to find it for you with
        our business network.
      </p>
      <button className="btn btn-sm btn-primary ml-3" onClick={handleOpenModal}>
        Contact Us
      </button>

      <Modal
        content={<AdminsContacts />}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Contacts"
      />
    </div>
  );
};

export default AdminsModal;
