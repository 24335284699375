import { getTimeDiffInMinutesFromNow } from "./productTypes";
import { LoginInfo } from "../components/LoginForm";
import { RegistrationInfo } from "../components/RegisterForm";
import { User } from "../hooks/useUser";
import auth from "./auth";
import cache from "./cache";
import client, { processResponse, getFailedResponse } from "./client";

type CachedUsers = { time: number; data: User[] };

const endpoint = "/users";
const CACHED_TIME_LIMIT_IN_MINUTES = 50;

const quickAuth = (info: { email: string; avatar: string; name: string }) =>
  client.post(`${endpoint}/quick`, info);

const register = (userInfo: RegistrationInfo) =>
  client.post(endpoint, userInfo);

const login = (userInfo: LoginInfo) => client.post("/auth", userInfo);

const updateUserInfo = (userInfo: object) => client.patch(endpoint, userInfo);

const restoreToken = async (email: string) => {
  const res = await client.post("/auth/token", { email });

  if (processResponse(res).ok) auth.loginWithJwt(res.data);
};

const getAdmins = async () => {
  try {
    return processResponse(await client.get(`${endpoint}/admin`));
  } catch (error) {
    return getFailedResponse(error);
  }
};

const getUser = (userId: string) => client.get(`${endpoint}/${userId}`);

const getUsers = async () => {
  try {
    const result = cache.get(endpoint) as CachedUsers | undefined;
    if (!result) return processResponse(await client.get(endpoint));

    if (getTimeDiffInMinutesFromNow(result.time) < CACHED_TIME_LIMIT_IN_MINUTES)
      return { data: result.data, ok: true, problem: "" };

    const res = processResponse(await client.get(endpoint));
    if (res.ok) {
      cache.store(endpoint, { time: Date.now(), data: res.data });
      return res;
    } else return { data: result.data, ok: true, problem: "" };
  } catch (error) {
    return getFailedResponse(error);
  }
};

const updateCart = (productId: string) =>
  client.patch(`${endpoint}/cart`, { productId });

export default {
  getAdmins,
  getUser,
  getUsers,
  login,
  quickAuth,
  register,
  restoreToken,
  updateCart,
  updateUserInfo,
};
