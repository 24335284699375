import { Shop } from "../hooks/useShop";

export type ShopAccount = {
  id: string;
  active: boolean;
  data: {
    name: string;
    image: string;
    feedToken: string;
  };
};

type Account = {
  userId: string;
  shops: ShopAccount[];
  shopsId: { [shopId: string]: string };
};

export const accountsKey = "amazing-shop/accounts";

const getAccounts = (): Account => {
  const value = localStorage.getItem(accountsKey);
  return value ? JSON.parse(value) : { shops: [], shopsId: {} };
};

const getAcitveAccount = () => getAccounts().shops.find((shop) => shop.active);

const saveShopAccount = (shop: ShopAccount) => {
  const account = getAccounts();
  account.shops.push(shop);
  saveAccountsToLocalStorage(account);
};

const saveShopsAccount = (shops: ShopAccount[]) => {
  const account = getAccounts();
  account.shops = [...account.shops, ...shops];
  saveAccountsToLocalStorage(account);
};

const saveAccountsToLocalStorage = (account: Account) =>
  localStorage.setItem(accountsKey, JSON.stringify(account));

const removeAccountById = (accountId: string) => {
  const accounts = getAccounts();

  accounts.shops = accounts.shops.filter((shop) => shop.id !== accountId);

  saveAccountsToLocalStorage(accounts);
};

const activateAccountById = (accountId: string, url?: string) => {
  const accounts = getAccounts();
  accounts.shops = accounts.shops.map((shop) =>
    shop.id === accountId
      ? { ...shop, active: true }
      : { ...shop, active: false }
  );

  saveAccountsToLocalStorage(accounts);
  url ? (window.location.href = url) : window.location.reload();
};

const activateAccount = (account: ShopAccount) => {
  activateAccountById(account.id);
  window.location.reload();
};

const saveAccount = (account: ShopAccount) => saveShopAccount(account);

const saveAccountThenActivate = (account: ShopAccount) => {
  saveAccount(account);
  activateAccountById(account.id);
};

const saveAccounts = (accounts: ShopAccount[]) => accounts.forEach(saveAccount);

const saveUserAndShopsAccount = (shops: Shop[]) => {
  const accounts = getAccounts();
  if (accounts.shops.length === shops.length) return;

  convertShopsToShopsAccountsAndSave(shops);
};

function convertShopsToShopsAccountsAndSave(shops: Shop[]) {
  const accounts = getAccounts();
  const ids = { ...accounts.shopsId };

  const shopsAccounts: ShopAccount[] = shops
    .filter((shop) => shop.author?._id === accounts.userId)
    .filter((shop) => !ids[shop?._id])
    .map((shop) => {
      ids[shop._id] = shop._id;

      return {
        active: false,
        data: { name: shop.name, image: shop.image, feedToken: shop.feedToken },
        id: shop._id,
        type: "shop",
      };
    });

  saveAccountsToLocalStorage({ ...accounts, shopsId: ids });
  saveShopsAccount(shopsAccounts);
}

const saveUserId = (userId: string) => {
  const accounts = getAccounts();

  saveAccountsToLocalStorage({ ...accounts, userId });
};

export default {
  activateAccount,
  activateAccountById,
  convertShopsToShopsAccountsAndSave,
  getAccounts,
  getAcitveAccount,
  removeAccountById,
  saveAccount,
  saveAccountThenActivate,
  saveAccounts,
  saveShopsAccount,
  saveUserAndShopsAccount,
  saveUserId,
};
