import { useNavigate, useLocation } from "react-router-dom";

import { useCart } from "../../hooks";
import CountBadge from "../CountBadge";

interface Props {
  count: number;
}

const Tabs = ({ count }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const cart = useCart();

  const isActive = (path: string) => location.pathname === path;

  return (
    <>
      {/* Don't remove the empty element, it helps in nav alignment */}
      <article className="flex-1 md:hidden" />
      <article className="hidden md:flex flex-1 justify-center space-x-14 relative">
        <p
          onClick={() => navigate("/")}
          className={`cursor-pointer ${
            isActive("/mart") ? "text-blue-500 font-bold" : ""
          }`}
        >
          Products
        </p>
        <p
          className={`relative cursor-pointer ${
            isActive("/mart/cart") ? "text-blue-500 font-bold" : ""
          }`}
          onClick={() => navigate("/mart/cart")}
        >
          Cart
          <CountBadge count={cart.count} />
        </p>
        <p
          className={`cursor-pointer ${
            isActive("/mart/orders") ? "text-blue-500 font-bold" : ""
          }`}
          onClick={() => navigate("/mart/orders")}
        >
          Orders
        </p>
        <p
          className={`cursor-pointer relative ${
            isActive("/chats") ? "text-blue-500 font-bold" : ""
          }`}
          onClick={() => navigate("/chats")}
        >
          Chats
          <CountBadge count={count} />
        </p>
      </article>
    </>
  );
};

export default Tabs;
