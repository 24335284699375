import { ProductType } from "../components/products/TypesList";
import cache from "./cache";
import client, { processResponse } from "./client";

const endpoint = "/types";
const CACHE_TIME_IN_MINUTES = 500;

type TypesCache = {
  time: number;
  data: ProductType[];
};

async function fetchAndCacheTypes() {
  const { data, ok } = processResponse(await client.get(endpoint));

  if (ok) {
    cache.store(endpoint, { time: Date.now(), data });
    return data as ProductType[];
  }

  return (cache.get(endpoint) as TypesCache)?.data || [];
}

const getProductTypes = async (): Promise<ProductType[]> => {
  try {
    return await fetchAndCacheTypes();
  } catch (error) {
    return [];
  }
};

export function getTimeDiffInMinutesFromNow(time: number) {
  return (Date.now() - time) / 1_000 / 60;
}

export default { getProductTypes };
