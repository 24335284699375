import { useState } from "react";
import { toast } from "react-toastify";

import { processResponse } from "../services/client";
import { useUser } from "../hooks";
import service from "../services/users";

const SellerWhatsAppNumberInput = () => {
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSaveWhatsapp = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (!whatsappNumber) return setError("Add a valid number");

    if (whatsappNumber.startsWith("254") || whatsappNumber.startsWith("+254")) {
      setLoading(true);
      if (error) setError("");
      const res = processResponse(
        await service.updateUserInfo({
          otherAccounts: { ...user?.otherAccounts, whatsapp: whatsappNumber },
        })
      );
      setLoading(false);

      res.ok
        ? toast.success(`WhatsApp number ${whatsappNumber} saved successfully!`)
        : toast.error("WhatsApp number update failed");
    } else {
      const errorMessage = "WhatsApp number must start with '254'";
      // toast.error(errorMessage);
      setError(errorMessage);
    }
  };

  return (
    <div className="flex flex-col md:flex-row w-full justify-center items-center px-3 mt-6 mb-2 mx-auto space-y-3 md:space-y-0 md:space-x-4">
      <p className="text-center md:text-left text-lg font-semibold">
        Have you added WhatsApp number?
      </p>
      <div className="flex flex-col md:flex-row items-center space-y-3 md:space-y-0 md:space-x-3">
        <input
          type="text"
          className="input input-bordered w-full md:w-auto"
          placeholder="+254.. (format)"
          value={whatsappNumber}
          onChange={(e) => setWhatsappNumber(e.target.value)}
        />
        {error && <p className="text-center text-sm text-red-600">{error}</p>}
        <button className="btn btn-primary" onClick={handleSaveWhatsapp}>
          {loading ? "Saving" : "Save Number"}
        </button>
      </div>
    </div>
  );
};

export default SellerWhatsAppNumberInput;
