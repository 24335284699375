import { useEffect, useRef } from "react";
import { useFeedContext, useStreamContext } from "react-activity-feed";
import { NotificationActivity } from "getstream";
import styled from "styled-components";

import FollowNotification from "./FollowNotification";
import OrderNotification from "./OrderNotification";
import ProductNotification from "./ProductNotification";

interface Props {
  activityGroup: NotificationActivity;
}

export default function NotificationGroup({ activityGroup }: Props) {
  const feed = useFeedContext();
  const notificationContainerRef = useRef<HTMLDivElement>(null);
  const { user, client } = useStreamContext();

  useEffect(() => {
    // stop event propagation on links
    if (!notificationContainerRef.current) return;

    const anchorTags = notificationContainerRef.current.querySelectorAll("a");

    anchorTags.forEach((element) => {
      element.addEventListener("click", (e) => e.stopPropagation());
    });

    return () =>
      anchorTags.forEach((element) => {
        element.removeEventListener("click", (e) => e.stopPropagation());
      });
  }, []);

  useEffect(() => {
    const notifFeed = client?.feed("notification", user?.id);

    notifFeed?.subscribe((data) => {
      if (data.new.length) feed.refresh();
    });

    return () => notifFeed?.unsubscribe();
  }, [client, user, feed]);

  return (
    <Container ref={notificationContainerRef}>
      {activityGroup.verb === "follow" && (
        <FollowNotification groupActivity={activityGroup} />
      )}
      {activityGroup.verb === "order" && (
        <OrderNotification groupActivity={activityGroup} />
      )}
      {activityGroup.verb === "post" && (
        <ProductNotification groupActivity={activityGroup} />
      )}
    </Container>
  );
}

const Container = styled.div`
  button {
    width: 100%;
  }
`;
