import React, { createContext } from "react";

export interface Cart {
  deleteAccount: boolean;
  setDeleteAccount: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteAccountContext = createContext<Cart>({
  deleteAccount: false,
  setDeleteAccount: () => {},
});

DeleteAccountContext.displayName = "Delete Account Context";

export default DeleteAccountContext;
