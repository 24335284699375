import React from "react";
import styled, { keyframes } from "styled-components";
import { FaSearch } from "react-icons/fa";

interface Props {
  onQuery: (query: string) => void;
  onSearch: () => void;
  placeholder: string;
  query: string;
}

const Header: React.FC<Props> = ({ onQuery, onSearch, query, placeholder }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onQuery(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") onSearch();
  };

  return (
    <HeaderContainer>
      <SearchContainer>
        <StyledInput
          placeholder={`Search ${placeholder}...`}
          value={query}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />
        <SearchButton onClick={onSearch}>
          <FaSearch />
        </SearchButton>
        {query && <ClearButton onClick={() => onQuery("")}>X</ClearButton>}
      </SearchContainer>
    </HeaderContainer>
  );
};

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HeaderContainer = styled.section`
  max-width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  animation: ${slideIn} 0.3s ease-out;
`;

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  background-color: inherit;
`;

const StyledInput = styled.input`
  flex-grow: 1;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  font-size: 1rem;
  border: none;
  outline: none;
  background-color: inherit; /* Inherits background color from parent */
`;

const SearchButton = styled.button`
  position: absolute;
  left: 0.8rem;
  color: #606060;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
`;

const ClearButton = styled.button`
  position: absolute;
  right: 0.8rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #606060;
  font-size: 1rem;
`;

export default Header;
