import React from "react";

import { Order } from "../../hooks/useOrder";

const AdminOrderCard: React.FC<Order> = (order) => {
  const { buyer, message, canceled, status, timestamp, products } = order;

  return (
    <article className="card bg-base-100 shadow-md cursor-pointer my-4 flex flex-col sm:flex-row justify-between items-center max-w-screen-md mx-auto">
      <div className="flex items-center space-x-4 w-full sm:w-auto">
        <figure>
          <img
            src={buyer.avatar}
            alt={buyer.name}
            className="w-24 h-24 object-cover"
          />
        </figure>
        <div className="card-body">
          <h2 className="card-title">{buyer.name}</h2>
          {message && <p className="text-sm">"{message}"</p>}
          <p>Status: {canceled ? "CANCELED" : status.label}</p>
          <p>Date & Time: {new Date(timestamp).toLocaleString()}</p>
          {/* <p>Products count: {Object.keys(products).length}</p> */}
        </div>
      </div>
    </article>
  );
};

export default AdminOrderCard;
