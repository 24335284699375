import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  BsCartCheckFill,
  BsInfoCircleFill,
  BsXCircleFill,
} from "react-icons/bs";

import { events, logEvent } from "../../db/analytics";
import { funcs } from "../../utils";
import { useCart, useProduct, useUser } from "../../hooks";
import service from "../../services/products";
import ShoppingCartIcon from "../ShoppingCartIcon";
import useProducts, { Product } from "../../hooks/useProducts";
import usersService from "../../services/users";

const ProductCard = (product: Product) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [productId, setProductId] = useState(product._id);
  const [showDescription, setShowDescription] = useState(false);
  const { setProduct } = useProduct();
  const { user } = useUser();
  const cart = useCart();
  const helper = useProducts();
  const navigate = useNavigate();

  const { _id, name, description, price, images, author } = product;

  const analyticsInfo = {
    productId,
    userId: user?._id,
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    if (!_id) {
      const saved = funcs.getSavedProducts();
      setProductId(saved[name.replace(" ", "_")]);
    }

    return () => clearInterval(intervalId);
  }, [images.length, productId]);

  const addedToCart = cart.hasProduct(productId);

  const navigateToDetails = () => {
    navigate(`/mart/products/${productId}`);
  };

  const handleClick = () => {
    setProduct(product);
    logEvent(events.eCommerce.VIEW_ITEM, analyticsInfo);
    navigateToDetails();
    updateViews();
  };

  const updateCart = () => {
    if (addedToCart) {
      logEvent(events.eCommerce.REMOVE_FROM_CART, analyticsInfo);
      cart.remove(productId);
    } else {
      logEvent(events.eCommerce.ADD_TO_CART, analyticsInfo);
      cart.add(productId);
    }

    if (user) usersService.updateCart(productId);
  };

  async function updateViews() {
    if (user) {
      const product = helper.findById(_id);
      const viewed = product?.views?.some((v) => v.viewer === user?._id);
      if (viewed) return;

      const res = await service.addView(product?._id || "");
      if (res.ok) {
        const update = res.data as Product;
        helper.findByIdAndUpdate(update._id, update);
      }
    }
  }

  return (
    <article className="w-full p-1 cursor-pointer relative overflow-hidden max-w-md">
      <article
        className={`card bg-base-100 shadow-xl relative ${
          showDescription ? "opacity-50" : ""
        }`}
      >
        <div className="image-container relative">
          {images.map((image, index) => (
            <img
              key={image}
              src={image}
              alt={name}
              onClick={handleClick}
              className={`w-full h-96 object-cover rounded-lg image ${
                currentImageIndex === index ? "active" : ""
              }`}
              style={{
                transform: `translateX(${
                  currentImageIndex === index ? 0 : "100%"
                })`,
                transition: "transform 0.5s ease-in-out",
              }}
            />
          ))}

          {showDescription ? (
            <BsXCircleFill
              size={24}
              className="absolute top-2 right-2 text-white cursor-pointer"
              onClick={() => setShowDescription(false)}
            />
          ) : (
            <BsInfoCircleFill
              size={24}
              className="absolute top-2 right-2 text-white cursor-pointer"
              onClick={() => setShowDescription(true)}
            />
          )}
        </div>

        <p className="text-lg mt-1 text-center">{`Ksh ${funcs.addComma(
          price
        )}`}</p>

        {user?._id !== author?._id && (
          <button
            className="btn btn-primary btn-block mt-2"
            onClick={updateCart}
          >
            {addedToCart ? (
              <>
                <BsCartCheckFill size={20} className="mr-2" />
                Added to Cart
              </>
            ) : (
              <>
                <ShoppingCartIcon />
                Add to Cart
              </>
            )}
          </button>
        )}
      </article>

      {showDescription && (
        <article
          className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-75 text-white p-4 flex flex-col items-center justify-center"
          onClick={() => setShowDescription(false)}
        >
          <h2 className="text-xl font-semibold mb-2">{name}</h2>
          <p>{description}</p>
        </article>
      )}

      <style>
        {`
          .image-container {
            position: relative;
            width: 100%;
            height: 0;
            padding-top: 75%;
            overflow: hidden;
          }

          .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }

          .image.active {
            opacity: 1;
          }
        `}
      </style>
    </article>
  );
};

export default ProductCard;
