import { createContext } from "react";

import { Product } from "../hooks/useProducts";

interface ContextValue {
  product: Product | undefined;
  setProduct: (product: Product) => void;
}

export const ProductContext = createContext<ContextValue>({
  product: undefined,
  setProduct: () => {},
});

ProductContext.displayName = "Product Context";

export default ProductContext;
