const keyPrefix = "amazing-cache/";

const store = (key: string, data: unknown) => {
  localStorage.setItem(keyPrefix + key, JSON.stringify(data));
};

const get = (key: string) => {
  const value = localStorage.getItem(keyPrefix + key);

  return value ? JSON.parse(value) : value;
};

const remove = (key: string) => {
  localStorage.removeItem(keyPrefix + key);
};

export default { store, get, remove };
