import { getAnalytics, logEvent as logAppEvent } from "firebase/analytics";

import { app } from "../services/auth";

export const events = {
  general: {
    APP_OPEN: "app_open",
    SESSION_START: "session_start",
    SCREEN_VIEW: "screen_view",
    USER_ENGAGEMENT: "user_engagement",
    FIREBASE_CAMPAIGN: "firebase_campaign",
    PAGE_VIEW: "page_view",
  },
  eCommerce: {
    ADD_TO_CART: "add_to_cart",
    REMOVE_FROM_CART: "remove_from_cart",
    BEGIN_CHECKOUT: "begin_checkout",
    CHECKOUT_PROGRESS: "checkout_progress",
    PURCHASE: "purchase",
    REFUND: "refund",
    VIEW_CART: "view_cart",
    VIEW_ITEM: "view_item",
    VIEW_ITEM_LIST: "view_item_list",
    SELECT_ITEM: "select_item",
    ADD_TO_WISHLIST: "add_to_wishlist",
    REMOVE_FROM_WISHLIST: "remove_from_wishlist",
  },
  userInteraction: {
    LOGIN: "login",
    SIGN_UP: "sign_up",
    SHARE: "share",
    SEARCH: "search",
    SELECT_CONTENT: "select_content",
    VIEW_SEARCH_RESULTS: "view_search_results",
  },
  ads: {
    AD_IMPRESSION: "ad_impression",
    AD_CLICK: "ad_click",
    EARN_VIRTUAL_CURRENCY: "earn_virtual_currency",
    SPEND_VIRTUAL_CURRENCY: "spend_virtual_currency",
    PRESENT_OFFER: "present_offer",
  },
  engagement: {
    LEVEL_UP: "level_up",
    UNLOCK_ACHIEVEMENT: "unlock_achievement",
    TUTORIAL_BEGIN: "tutorial_begin",
    TUTORIAL_COMPLETE: "tutorial_complete",
  },
  location: {
    LOCATION_UPDATE: "location_update",
    VIEW_PROMOTION: "view_promotion",
    SELECT_PROMOTION: "select_promotion",
  },
  media: {
    PLAY: "play",
    PAUSE: "pause",
    WATCH_VIDEO: "watch_video",
    JOIN_GROUP: "join_group",
  },
};

export function logEvent(event: string, eventParams?: object) {
  // logAppEvent(getAnalytics(app), event, eventParams);
}
