import { createContext } from "react";

import { Product } from "../hooks/useProducts";

interface ProductsContextValue {
  products: Product[];
  setProducts: (products: Product[]) => void;
  isLoading: boolean;
}

export const ProductsContext = createContext<ProductsContextValue>({
  products: [],
  setProducts: () => {},
  isLoading: false,
});

ProductsContext.displayName = "Products Context";

export default ProductsContext;
