import { useField } from "formik";

interface FormCheckboxFieldProps {
  name: string;
  label: string;
}

const FormCheckboxField = ({ name, label }: FormCheckboxFieldProps) => {
  const [field, meta] = useField({ name, type: "checkbox" });

  return (
    <div className="form-group">
      <label className="checkbox-label">
        <input type="checkbox" {...field} checked={field.value} />
        {label}
      </label>
      {meta.touched && meta.error ? (
        <div className="error-text ml-2">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default FormCheckboxField;
