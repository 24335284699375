import React, { lazy, Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "stream-chat-react/dist/css/index.css";
import "react-activity-feed/dist/index.css";
import * as tf from "@tensorflow/tfjs";
import "@tensorflow/tfjs-backend-webgl";

import { LoadingPage } from "./pages";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

const LazyApp = lazy(() => import("./App"));

const Root = () => {
  const [tfReady, setTfReady] = useState(false);

  useEffect(() => {
    const initTensorFlow = async () => {
      await tf.setBackend("webgl");
      await tf.ready();

      setTfReady(true);
    };

    initTensorFlow();
  }, []);

  if (!tfReady) return <LoadingPage />;

  return (
    <Suspense fallback={<LoadingPage />}>
      <React.StrictMode>
        <BrowserRouter>
          <LazyApp />
          <ToastContainer />
        </BrowserRouter>
      </React.StrictMode>
    </Suspense>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<Root />);

reportWebVitals();
