import { PropsWithChildren } from "react";

interface LoadingIndicatorProps {
  isLoading?: boolean;
}

export default function LoadingIndicator({
  children,
  isLoading,
}: PropsWithChildren<LoadingIndicatorProps>) {
  return (
    <div className="relative min-h-screen">
      {children}

      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="w-12 h-12 border-4 border-white border-t-4 border-t-transparent rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
}
