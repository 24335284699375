import { useState } from "react";
import { jwtDecode } from "jwt-decode";

type AnonymousUser = {
  _id: string;
  name: string;
  token: string;
};

const jwt =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGNlM2VhM2FhM2VlZWJlOGJhOWE4MjMiLCJuYW1lIjoiQW5vbnltb3VzIFVzZXIiLCJ0b2tlbiI6ImV5SmhiR2NpT2lKSVV6STFOaUlzSW5SNWNDSTZJa3BYVkNKOS5leUoxYzJWeVgybGtJam9pTmpSalpUTmxZVE5oWVRObFpXVmlaVGhpWVRsaE9ESXpJbjAuOGFOTmRqTWFTUERlSVdQZ3ZFWEFRTFlIMUNuZzRfaXlFeGc4NU93eWtWZyJ9.5a7alhY3nk6e5kW9QqbPp3qTxqldpB4srO-xpythS4E";

const useAnonymousUser = () => {
  const [anonymousUser] = useState<AnonymousUser>(jwtDecode(jwt));

  return anonymousUser;
};

export default useAnonymousUser;
