import ProductForm from "../components/products/Form";

const NewProductPage = () => {
  return (
    <div className="px-5">
      <ProductForm />
    </div>
  );
};

export default NewProductPage;
