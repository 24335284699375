import { useEffect, useState } from "react";
import { ProductType } from "../components/products/TypesList";
import service from "../services/productTypes";

const sortProductTypes = (types: ProductType[]): ProductType[] => {
  return types.sort((a, b) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();
    if (labelA < labelB) return -1;
    if (labelA > labelB) return 1;
    return 0;
  });
};

const useProductTypes = () => {
  const [types, setTypes] = useState<ProductType[]>([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    async function init() {
      setLoading(true);
      const all = [
        { _id: "", label: "All" },
        ...(await service.getProductTypes()),
      ];

      const sortedTypes = sortProductTypes(all);

      setTypes(sortedTypes.filter((t) => t.label));
      setLoading(false);
    }

    init();
  }, []);

  return { isLoading, types };
};

export default useProductTypes;
