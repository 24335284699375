import { Product } from "../hooks/useProducts";
import cache from "../services/cache";

const key = "/products";

const store = (products: Product[]) => {
  const result = cache.get(key) as Product[];

  if (result.length === products.length) return;

  let results: { [name: string]: string } = {};
  products.forEach(({ name, _id }) => {
    const keyName = name.replace(" ", "_");
    if (!results[keyName]) results[keyName] = _id;
  });

  cache.store(key, products);
};

const get = (): Product[] => {
  const result = cache.get(key) as
    | { time: number; data: Product[] }
    | undefined;

  if (result && Array.isArray(result.data)) return result.data;

  return [];
};

export default { get, store };
