import { NotificationFeed } from "react-activity-feed";
import { Navigate } from "react-router-dom";

import { useUser } from "../hooks";
import accounts from "../services/accounts";
import EmptyNotificationsPlaceholder from "../components/notifications/EmptyNotificationsPlaceholder";
// import LoadingIndicator from "../components/LoadingIndicator";
import NotificationGroup from "../components/notifications/NotificationGroup";

const NotificationsPage = () => {
  const { user } = useUser();

  if (!user) return <Navigate to="/" />;

  if (!user?.feedToken && !accounts.getAcitveAccount()?.data.feedToken)
    return null;

  return (
    <div className="max-w-3xl mx-auto px-4">
      <h1 className="mb-4 font-bold text-xl">Notifications</h1>
      <NotificationFeed
        Group={NotificationGroup}
        // LoadingIndicator={LoadingIndicator}
        Placeholder={<EmptyNotificationsPlaceholder />}
      />
    </div>
  );
};

export default NotificationsPage;
