import { useParams } from "react-router-dom";

import { useProducts } from "../../hooks";
import ProductCard from "../products/Card";
import Grid from "../Grid";

const ProfileProducts = () => {
  const { userId } = useParams();
  const { products } = useProducts();

  return (
    <div>
      <Grid>
        {products
          .filter((p) => p.author?._id === userId)
          .map((p) => (
            <ProductCard {...p} key={p._id} />
          ))}
      </Grid>
    </div>
  );
};

export default ProfileProducts;
