import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { cancelledStatus } from "./ShopOrdersPage";
import { emptyStatus } from "../utils/empty";
import { Order } from "../hooks/useOrder";
import { paginate } from "../utils";
import { Pagination, ProductTypesList } from "../components";
import { Status } from "../hooks/useStatus";
import { useStatus, useUser } from "../hooks";
import AdminOrderCard from "../components/orders/AdminOrderCard";
import OrderCard from "../components/orders/Card";
import service from "../services/orders";
import TabList, { Tab } from "../components/TabList";

type TabName = "outgoing" | "all" | "incoming";

const initialTabs: Tab<TabName>[] = [
  {
    tabLabel: "Outgoing Orders",
    tabName: "outgoing",
  },
  {
    tabLabel: "Incoming Orders",
    tabName: "incoming",
  },
];

const adminTab: Tab<TabName> = {
  tabLabel: "All Orders",
  tabName: "all",
};

const OrdersPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(6);
  const [orders, setOrders] = useState<Order[]>([]);
  const [allOrders, setAllOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);
  const { status } = useStatus();
  const [selectedStatus, setSelectedStatus] = useState<Status>(emptyStatus);
  const { user } = useUser();
  const [tabs, setTabs] = useState<Tab<TabName>[]>([]);
  const [activeTab, setActiveTab] = useState<TabName>(initialTabs[0].tabName);

  useEffect(() => {
    function initTabs() {
      const tabs = [...initialTabs];

      if (user?.isAdmin) tabs.push(adminTab);

      setTabs(tabs);
    }

    const retrieveAllOrders = async () => {
      if (user?.isAdmin && !allOrders.length) {
        const res = await service.getAllOrders();

        res.ok
          ? setAllOrders(res.data as Order[])
          : toast.error("Error fetching all orders");
      }
    };

    initTabs();
    retrieveAllOrders();
  }, [user]);

  useEffect(() => {
    const initData = async () => {
      setLoading(true);
      if (user?._id && !orders.length)
        setOrders(await service.getShopOrUserOrders(user._id));
      setLoading(false);
    };

    initData();
  }, [user?._id, user, activeTab]);

  const filtered = selectedStatus._id
    ? orders.filter((order) => {
        if (selectedStatus.label === cancelledStatus.label)
          return order.canceled;
        else return order.status._id === selectedStatus._id && !order.canceled;
      })
    : orders;

  const paginated = paginate<Order>(filtered, currentPage, pageSize);

  const showRightOrders = (order: Order): boolean => {
    if (activeTab === "incoming") return order.shop.author._id === user?._id;
    if (activeTab === "outgoing") return order.buyer._id === user?._id;
    return false;
  };

  const activateTab = (activeTab: TabName) => {
    setActiveTab(activeTab);
    setSelectedStatus(emptyStatus);
  };

  const renderTabExplanation = (): JSX.Element => {
    if (activeTab === "outgoing")
      return (
        <p className="text-center mb-4 italic">
          (Orders you have made to other shops.)
        </p>
      );

    if (activeTab === "incoming")
      return (
        <p className="text-center mb-4 italic">
          (Orders other users have made to your shop.)
        </p>
      );

    if (activeTab === "all")
      return (
        <p className="text-center mb-4 italic">
          (All orders across the platform - Admin view only.)
        </p>
      );

    return <></>;
  };

  return (
    <section className="px-5">
      <div className="bg-primary text-white text-center p-4 rounded-md mb-6">
        <h2 className="text-xs sm:text-sm font-bold">
          Orders are made without payments. You will be contacted by the seller
          to arrange payment and delivery details.
        </h2>
      </div>

      <TabList<TabName>
        activeTab={activeTab}
        onTabClick={activateTab}
        tabs={tabs}
      />

      {renderTabExplanation()}

      <h1 className="text-2xl font-bold mb-4 text-center">
        {loading && <span className="loading loading-dots loading-md" />}
      </h1>

      {Boolean(paginated.length) && (
        <ProductTypesList
          badges={[...status, cancelledStatus]}
          onTypeSelect={(item) => setSelectedStatus(item as Status)}
          selectedType={selectedStatus}
        />
      )}

      {activeTab === "outgoing" && !paginated.length && !loading && (
        <h2 className="text-xl mt-4 text-center">
          {selectedStatus._id
            ? `${selectedStatus.label} orders not found`
            : "You don't have any orders yet. Add products to your cart and place an order to see it here."}
        </h2>
      )}

      {(activeTab === "incoming" || activeTab === "outgoing") &&
        paginated
          .filter(showRightOrders)
          .map((order) => <OrderCard key={order._id} {...order} />)}

      {activeTab === "all" &&
        allOrders
          .filter((order) => !Array.isArray(order))
          .map((order) => <AdminOrderCard {...order} key={order._id} />)}

      <Pagination
        currentPage={currentPage}
        itemsCount={orders.length}
        onPageChange={setCurrentPage}
        pageSize={pageSize}
      />
    </section>
  );
};

export default OrdersPage;
