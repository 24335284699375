import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";

import {
  ErrorMessage,
  Form,
  FormField,
  FormTextAreaField,
  SubmitButton,
} from "../form";
import { useImages } from "../../hooks";
import ImageInputList from "../common/ImageInputList";
import service from "../../services/service";
import storage from "../../db/image";

const schema = Yup.object().shape({
  name: Yup.string().min(3).required().label("Shop Name"),
  price: Yup.number().min(1).required().label("Service fee"),
  description: Yup.string().label("Service description"),
});

export type ServiceInfo = Yup.InferType<typeof schema>;

const MAX_IMAGE_INPUT = 5;

interface Props {
  onDone: () => void;
}

const NewServiceForm = ({ onDone }: Props) => {
  const [error, setError] = useState("");
  const { imagesCount, images, removeAllImages } = useImages(MAX_IMAGE_INPUT);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (info: ServiceInfo) => {
    if (!imagesCount) return setError("Select an image");

    setLoading(true);
    toast.loading("Saving your service...");
    const imagesUrl = await storage.saveImages(images);
    const res = await service.create({ ...info, images: imagesUrl });
    toast.dismiss();
    setLoading(false);

    if (res.ok) {
      toast.success("Service created successfully!");
      removeAllImages();
      onDone();
    } else {
      toast.error("Service creation failed! Something went wrong!");
      await storage.deleteImages(imagesUrl);
    }
  };

  return (
    <Form
      initialValues={{ name: "", price: 0, description: "" }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <ImageInputList imagesLimit={MAX_IMAGE_INPUT} />
      <p className="text-sm">
        Select evident images. (Served customers or work done)
      </p>
      <ErrorMessage error={error} />
      <FormField name="name" />
      <FormField name="price" type="number" />
      <FormTextAreaField
        name="description"
        placeholder="Describe your service including how you charge each service"
      />
      <SubmitButton title={loading ? "Saving service" : "Save Service"} />
    </Form>
  );
};

export default NewServiceForm;
