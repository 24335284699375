import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useStreamContext } from "react-activity-feed";

import {
  ErrorMessage,
  Form,
  FormField,
  FormTextAreaField,
  SubmitButton,
} from "../form";
import { Activity } from "../products/Activity";
import { NewShopTypes, Shop, UpdatableShopInfo } from "../../hooks/useShop";
import { ProductType } from "../products/TypesList";
import { shopSchema } from "../../pages/ShopEditPage";
import { useDeleteAccount, useProductTypes, useUser } from "../../hooks";
import Modal from "../Modal";
import service from "../../services/shops";
import ShopTypesSelector, { ShopTypes } from "./TypesSelector";
import useShops from "../../hooks/useShops";
import accounts from "../../services/accounts";
import LoadingIndicator from "../LoadingIndicator";

interface Props extends Shop {
  onDone: () => void;
}

const UpdateForm = ({
  deliveryInfo,
  onDone,
  location,
  name,
  types,
  _id,
}: Props) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedShopTypes, setSelectedShopTypes] = useState<ShopTypes>({});
  const { client } = useStreamContext();
  const { types: allTypes } = useProductTypes();
  const helper = useShops();
  const shopFeed = client?.feed("user", _id);
  const { setDeleteAccount } = useDeleteAccount();
  const { user } = useUser();
  const [selectedShopTypesId, setSelectedShopTypesId] = useState<NewShopTypes>(
    {}
  );

  useEffect(() => {
    async function getShopActivities() {
      try {
        const activities = (await shopFeed?.get())?.results;

        if (activities) setActivities(activities as unknown as Activity[]);
      } catch (error) {
        console.log(`Error fetching shop activities => ${error}`);
      }
    }

    const prepareTypes = () => {
      let output: ShopTypes = {};
      let outputIds: NewShopTypes = {};

      allTypes.forEach((type) => {
        if (types[type._id]) {
          output = { ...output, [type._id]: type };
          outputIds[type._id] = type._id;
        }
      });

      setSelectedShopTypesId(outputIds);
      setSelectedShopTypes(output);
    };

    getShopActivities();
    prepareTypes();
  }, [allTypes]);

  const handleSubmit = async (info: UpdatableShopInfo) => {
    if (loading) return;

    if (!Object.keys(selectedShopTypesId).length)
      return setError("Select at least one shop type");

    setLoading(true);
    toast.loading("Updating shop details...Please wait");
    const res = await service.update(
      { ...info, types: selectedShopTypesId },
      _id
    );
    toast.dismiss();
    setLoading(false);

    if (!res?.ok) return setError(res?.problem || "Shop Update failed");

    onDone();
    toast.success("Shop updated successfully");
    window.location.reload();
  };

  const handleTypeSelect = (type: ProductType) => {
    if (!selectedShopTypes[type._id]) {
      setSelectedShopTypes({ ...selectedShopTypes, [type._id]: type });
      setSelectedShopTypesId({
        ...selectedShopTypesId,
        [type._id]: type._id,
      });
    } else {
      const newTypes = { ...selectedShopTypes };
      delete newTypes[type._id];
      const newTypesId = { ...selectedShopTypesId };
      delete newTypesId[type._id];
      setSelectedShopTypes(newTypes);
      setSelectedShopTypesId(newTypesId);
    }
  };

  const deleteShop = async () => {
    if (loading) return;
    if (!_id) return toast.error("Error! Try again later");

    activities.forEach(
      async (activity) => await shopFeed?.removeActivity(activity)
    );
    setDeleteAccount(true);
    await helper.deleteShop(_id);
    accounts.removeAccountById(_id);

    accounts.activateAccountById(user?._id || "", "/");
  };

  return (
    <LoadingIndicator isLoading={loading}>
      <Modal
        content="Are you certain you want to permanently delete this shop? This action will remove all associated products and cannot be undone"
        isOpen={confirmDeletion}
        onClose={() => setConfirmDeletion(false)}
        title="Shop Deletion Confirmation"
        primaryBtnLabel="Confirm, Delete Shop"
        secondaryBtnLabel="Cancel"
        onPrimaryBtnClick={deleteShop}
      />

      <Form
        initialValues={{
          deliveryInfo: deliveryInfo || "We will discuss on delivery method",
          name,
          location,
          types: selectedShopTypesId,
        }}
        onSubmit={handleSubmit}
        validationSchema={shopSchema}
      >
        <ErrorMessage error={error} visible />
        <ShopTypesSelector
          onTypeSelect={handleTypeSelect}
          selectedTypes={selectedShopTypes}
        />
        <FormField name="name" />
        <FormTextAreaField name="deliveryInfo" />
        <FormTextAreaField name="location" />
        <SubmitButton title={loading ? "Updating" : "Update"} />
      </Form>
      <div className="divider">OR</div>
      <button
        className="btn btn-error w-full"
        onClick={() => setConfirmDeletion(true)}
      >
        Delete Shop
      </button>
    </LoadingIndicator>
  );
};

export default UpdateForm;
