import { endpoint as productsEndpoint } from "./products";
import { NewShop, Shop, UpdatableShopInfo } from "../hooks/useShop";
import { Product } from "../hooks/useProducts";
import client, {
  emptyResponse,
  getFailedResponse,
  processResponse,
  Response,
} from "./client";
import { getTimeDiffInMinutesFromNow } from "./productTypes";
import cache from "./cache";

type CachedShops = { time: number; data: Shop[] };

const endpoint = "/shops";
const CACHE_MINUTES_LIMIT = 10;

const create = async (shop: NewShop) => {
  try {
    return processResponse(await client.post("/shops", shop));
  } catch (error) {
    return emptyResponse;
  }
};

export const getShopProducts = async (shopId: string): Promise<Product[]> => {
  try {
    const result = cache.get("/products") as
      | { time: number; data: Product[] }
      | undefined;
    if (!result) {
      const res = processResponse(
        await client.get(`${productsEndpoint}/${shopId}`)
      );

      return res.ok ? (res.data as Product[]) : [];
    }

    return result.data.filter((p) => p.shop._id === shopId);
  } catch (error) {
    return [];
  }
};

const update = async (shop: UpdatableShopInfo, shopId: string) => {
  try {
    return processResponse(await client.patch(`${endpoint}/${shopId}`, shop));
  } catch (error) {
    return emptyResponse;
  }
};

const getShop = (shopNameOrUserId: string) =>
  client.get(`${endpoint}/${shopNameOrUserId}`);

const getShops = async () => {
  try {
    const result = cache.get(endpoint) as CachedShops | undefined;

    if (!result) return processResponse(await client.get(endpoint));
    if (getTimeDiffInMinutesFromNow(result.time) < CACHE_MINUTES_LIMIT)
      return getResponseWithData<Shop[]>(result.data);

    const res = processResponse(await client.get(endpoint));
    if (res.ok) {
      cache.store(endpoint, { time: Date.now(), data: res.data });
      return res;
    } else return getResponseWithData<Shop[]>(result.data);
  } catch (error) {
    return getFailedResponse(error);
  }
};

function getResponseWithData<T>(data: T): Response {
  return { data, ok: true, problem: "" };
}

const incViews = async (shopId: string) => {
  try {
    return processResponse(await client.patch(`${endpoint}/views/${shopId}`));
  } catch (error) {}
};

const deleteShop = async (shopId: string) => {
  try {
    return processResponse(await client.delete(`${endpoint}/${shopId}`));
  } catch (error) {}
};

export default {
  create,
  deleteShop,
  incViews,
  getShop,
  getShopProducts,
  getShops,
  update,
};
