import { useStreamContext } from "react-activity-feed";

import { Product } from "./useProducts";

const useNotifications = () => {
  const { client } = useStreamContext();

  const createNotification = async (
    userId: string,
    verb: string,
    data = {},
    reference = {}
  ) => {
    const userNotificationFeed = client?.feed("notification", userId);

    await userNotificationFeed?.addActivity({
      actor: `SU:${client?.userId}`,
      object: reference,
      verb,
      ...data,
    });
  };

  const createProductNotificationForShopSubscribers = async (
    product: Product
  ) => {
    const shopFeed = client?.feed("user", product.shop._id);

    const subscribers = (await shopFeed?.followers())?.results || [];
    subscribers?.forEach(async (subscriber) => {
      const id = subscriber.feed_id.replace("timeline:", "");

      await createNotification(id, "post", {
        productId: product._id,
        shopName: product.shop.name,
      });
    });
  };

  return { createNotification, createProductNotificationForShopSubscribers };
};

export default useNotifications;
