import client, { getFailedResponse, processResponse } from "./client";

const endpoint = "/mailing";

interface Mail {
  name: string;
  intro: string;
  to: string | string[];
  subject: string;
}

export const sendMail = async (mail: Mail) => {
  try {
    return processResponse(await client.post(endpoint, mail));
  } catch (error) {
    return getFailedResponse(error);
  }
};
