interface Props {
  src: string | undefined;
  onClick: () => void;
}

const Avatar = ({ onClick, src }: Props) => {
  return (
    <div className="avatar cursor-pointer" onClick={onClick}>
      <div className="w-7 rounded-full mr-2">
        <img src={src} className="rounded-full" />
      </div>
    </div>
  );
};

export default Avatar;
