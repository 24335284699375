import { useState } from "react";

import { Input } from "../components";
import { sendMail } from "../services/mail";
import { User } from "../hooks/useUser";
import { useShops, useUsers } from "../hooks";

type Recievers = "sellers" | "buyers" | "admins";

const AdminPage = () => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [receiver, setReciever] = useState<Recievers>("sellers");
  const { shops } = useShops();
  const { users } = useUsers();

  function getSellersMail(): string[] {
    return shops
      .map((shop) => shop.author?.email)
      .filter((email) => typeof email === "string" && email);
  }

  function isAdmin(user: User): boolean {
    return user.isAdmin;
  }

  function getReceiversMail(): string[] {
    if (receiver === "admins")
      return users
        .filter(isAdmin)
        .map((admin) => admin.email)
        .filter((email) => typeof email === "string" && email);

    if (receiver === "buyers") {
      const sellersMail: { [email: string]: string } = {};

      getSellersMail().forEach((mail) => {
        sellersMail[mail] = mail;
      });

      return users
        .filter((user) => !sellersMail[user.email])
        .filter((user) => !isAdmin(user))
        .map((buyer) => buyer.email);
    }

    if (receiver === "sellers") return getSellersMail();

    return [];
  }

  const getReceiverName = (): string => {
    if (receiver === "admins") return "Admin";
    if (receiver === "sellers") return "Sellers";
    return "Shoppers";
  };

  const send = async () => {
    const emails = getReceiversMail();

    setLoading(true);
    const res = await sendMail({
      intro: message,
      name: getReceiverName(),
      subject,
      to: emails,
    });
    setLoading(false);

    if (res.ok) {
      setMessage("");
      setSubject("");
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center h-screen px-4">
        <div className="w-full max-w-md bg-white p-6 rounded-md shadow-md">
          <h1 className="text-2xl font-semibold text-center mb-6">
            Send Email to:
          </h1>

          <select
            value={receiver}
            onChange={(e) => setReciever(e.target.value as Recievers)}
            className="select select-bordered w-full mb-4"
          >
            <option value="sellers">Sellers</option>
            <option value="buyers">Buyers</option>
            <option value="admins">Admins</option>
          </select>

          <div className="mb-4">
            <Input
              onChange={setSubject}
              placeholder="Subject"
              value={subject}
            />
          </div>

          <div className="mb-4">
            <Input
              onChange={setMessage}
              placeholder="Message"
              value={message}
            />
          </div>

          {/* Send Button */}
          <button
            onClick={send}
            className="btn btn-primary w-full mt-4"
            disabled={loading}
          >
            {loading ? "Sending..." : "Send Message"}
          </button>
        </div>
      </div>
    </>
  );
};

export default AdminPage;
