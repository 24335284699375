import React from "react";
import Lottie from "react-lottie-player";
import styled, { keyframes } from "styled-components";

interface Props {
  progress: number;
  onDone: () => void;
}

const UploadPage: React.FC<Props> = ({ progress, onDone }) => {
  const percentageProgress = progress * 100;

  return (
    <Wrapper>
      {progress === 0 ? (
        <ProgressContainer>
          <IndeterminateBar />
          <p className="mt-2 text-lg text-center">Preparing upload...</p>
        </ProgressContainer>
      ) : percentageProgress < 100 ? (
        <>
          <h1 className="text-2xl font-bold mb-4">Upload Progress</h1>
          <div className="w-full max-w-xs">
            <progress
              className="progress progress-primary"
              value={percentageProgress}
              max="100"
            />
          </div>
          <p className="mt-2">{percentageProgress.toFixed(0)}%</p>
        </>
      ) : (
        <div className="mt-4">
          <Lottie
            play
            animationData={require("../assets/animations/done.json")}
            style={{ width: 150 }}
            loop={false}
            onAnimationEnd={onDone}
          />
          <p className="text-xl">Upload Complete!</p>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const ProgressContainer = styled.div`
  width: 100%;
  max-width: 320px;
  text-align: center;
`;

const slide = keyframes`
  0% {
    left: -40%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 100%;
  }
`;

const IndeterminateBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 40%;
    height: 100%;
    background-color: #3b82f6; /* Customize as needed */
    border-radius: 4px;
    animation: ${slide} 1.5s infinite ease-in-out;
  }
`;

export default UploadPage;
