import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { toast } from "react-toastify";
import { FcGoogle } from "react-icons/fc";
import { FiUpload } from "react-icons/fi";

import { googleAuth } from "../services/auth";
import { AdminsModal, Modal, ShopSelectors } from "../components";
import { useSearchParams, useUser } from "../hooks";
import ProductsPage from "./ProductsPage";
import WhatsAppNumberInput from "../components/WhatsAppNumberInput";

const HomePage = () => {
  const [selectedShopId, setSelectedShopId] = useState("");
  const [showShopsModal, setShowShopsModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const { user } = useUser();
  const navigate = useNavigate();
  useSearchParams();

  const handleShopSelection = () => {
    if (selectedShopId) {
      navigate(`shops/${selectedShopId}`);
      return setShowShopsModal(false);
    }

    toast.info("Please select a shop or create a new one");
  };

  const handleProductCreation = () => {
    if (selectedShopId) {
      setShowShopsModal(false);
      navigate(`/mart/products/new?shopId=${selectedShopId}`);
    }
  };

  const login = async () => {
    setShowAuthModal(false);
    await signInWithPopup(googleAuth, new GoogleAuthProvider());
  };

  const navigateToShopEditPage = () => {
    setShowShopsModal(false);
    navigate("shops/new");
  };

  const ShopsModalContent = (
    <>
      <p
        className="text-center cursor-pointer mb-2 font-bold"
        onClick={navigateToShopEditPage}
      >
        Create a new shop instead?
      </p>
      <ShopSelectors
        onDoneShopSelect={handleShopSelection}
        onShopSelect={setSelectedShopId}
        selectedShop={selectedShopId}
      />
    </>
  );

  const AuthModalContent = (
    <>
      <p className="text-center mb-4">
        Buyers need to know who they're buying from you
      </p>
      <button
        className="btn btn-primary w-full flex justify-center items-center space-x-2"
        onClick={login}
      >
        <span>Login with Google</span>
        <FcGoogle />
      </button>
      <button
        className="btn btn-secondary w-full mt-4"
        onClick={() => navigate("/auth")}
      >
        Login with Forms
      </button>
    </>
  );

  return (
    <div>
      <Modal
        content={ShopsModalContent}
        isOpen={showShopsModal}
        onClose={() => setShowShopsModal(false)}
        title="Select Shop"
        primaryBtnLabel="Proceed"
        onPrimaryBtnClick={handleProductCreation}
        secondaryBtnLabel="Cancel"
      />
      <Modal
        content={AuthModalContent}
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        title="Log in to Continue"
      />

      {!user && <p className="text-center mb-2">Login to </p>}
      <div className="flex flex-col w-full justify-center items-center px-3 mb-2 mx-auto space-y-4">
        <div className="flex flex-row w-full justify-center items-center space-x-4">
          <button
            className="btn btn-sm btn-primary w-auto flex items-center space-x-1"
            onClick={() =>
              user ? setShowShopsModal(true) : setShowAuthModal(true)
            }
          >
            <FiUpload /> <span>Upload Product</span>
          </button>
        </div>

        {user?.isAdmin ? (
          <button
            className="btn btn-sm btn-outline btn-secondary"
            onClick={() => navigate("/admin")}
          >
            View Admin Panel
          </button>
        ) : (
          <AdminsModal />
        )}
      </div>

      <WhatsAppNumberInput />
      <ProductsPage />
    </div>
  );
};

export default HomePage;
