import { ChangeEvent, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { toast } from "react-toastify";

import { useImages } from "../../hooks";
import ImagePicker from "./ImagePicker";

interface Props {
  imagesLimit: number;
}

const ImageInputList = ({ imagesLimit }: Props) => {
  const { addImages, imagesCount, removeImage } = useImages(imagesLimit);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  const addNewFiles = (oldItems: string[], newItems: string[]) =>
    [...oldItems, ...newItems].slice(0, imagesLimit);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    try {
      const files = event.target.files;

      if (files) {
        const newSelectedFiles = Array.from(files);
        addImages(newSelectedFiles);

        const newPreviews = newSelectedFiles.map((file) =>
          URL.createObjectURL(file)
        );
        setImagePreviews(addNewFiles(imagePreviews, newPreviews));
      }
    } catch (error) {
      toast.info(
        "Amazing camera isn't working! Take pictures with your phone camera instead and select those pictures"
      );
    }
  };

  const unSelectImageBy = (imageIndex: number) => {
    removeImage(imageIndex);

    setImagePreviews(
      imagePreviews.filter((preview, index) => {
        if (index !== imageIndex) return preview;
      })
    );
  };

  return (
    <div className="max-w-xs mb-2 mr-2">
      <div className="flex overflow-x-auto">
        <div className="flex">
          <ImagePicker
            onChange={handleFileChange}
            visible={imagesCount < imagesLimit}
          />
          {imagePreviews.map((preview, index) => (
            <div key={index} className="relative m-1 w-24 h-24">
              <img
                src={preview}
                alt={`Selected Image ${index + 1}`}
                className="w-24 h-24 object-cover rounded-md cursor-pointer m-1"
                onClick={() => unSelectImageBy(index)}
              />
              <div
                className="absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2 bg-red-600 text-white rounded-full p-1 cursor-pointer"
                onClick={() => unSelectImageBy(index)}
              >
                <BsTrash />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageInputList;
