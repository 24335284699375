import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { User } from "../../hooks/useUser";
import ArrowLeft from "../../assets/ArrowLeft";
import { useProducts, useShops } from "../../hooks";

interface Props {
  user: User | undefined;
}

const ProfileHeader = ({ user }: Props) => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { shops } = useShops();
  const { products } = useProducts();

  const shopsCount = shops.filter((s) => s.author?._id === userId).length;
  const productsCount = products.filter((p) => p.author?._id === userId).length;

  const navigateBack = () => navigate(-1);

  return (
    <>
      <StickyHeader>
        <button onClick={navigateBack}>
          <ArrowLeft size={20} color="white" />
        </button>
        <div className="info">
          <h1>{user?.name || "Display user name here"}</h1>
          <span className="info__tweets-count">
            {shopsCount} Shop{shopsCount === 1 ? "" : "s"} with {productsCount}{" "}
            product{productsCount === 1 ? "" : "s"}
          </span>
        </div>
      </StickyHeader>
      <Header>
        <div className="cover">
          <img src="https://picsum.photos/500/200" alt="" />
        </div>
      </Header>
    </>
  );
};

const StickyHeader = styled.div`
  align-items: center;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  padding: 10px 15px;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;

  .info {
    margin-left: 30px;

    h1 {
      font-size: 20px;
    }

    &__tweets-count {
      font-size: 14px;
      margin-top: 2px;
      color: #888;
    }
  }
`;

const Header = styled.header`
  .cover {
    width: 100%;
    background-color: #555;
    height: 150px;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
`;

export default ProfileHeader;
