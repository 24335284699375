import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  RiStore2Line,
  RiShoppingCart2Line,
  RiCalendarEventLine,
  RiCloseLine,
} from "react-icons/ri";
import { FaImages } from "react-icons/fa";
import { BsHeadset } from "react-icons/bs";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";

import { useUser } from "../../hooks";
import Account from "../accountSwitch/Account";
import accounts, { ShopAccount } from "../../services/accounts";
import logo from "../../assets/logo.png";

interface Props {
  onContentClick: () => void;
  onSwitchAccounts: () => void;
}

const DrawerContent = ({ onContentClick, onSwitchAccounts }: Props) => {
  const [activeAccount, setActiveAccount] = useState<ShopAccount>();
  const navigate = useNavigate();
  const currentTab = window.location.pathname;
  const { user } = useUser();

  useEffect(() => {
    setActiveAccount(accounts.getAcitveAccount());
  }, []);

  const closeDrawer = () => onContentClick();

  const navigateToProducts = () => {
    closeDrawer();
    navigate("/mart");
  };

  const navigateToShops = () => {
    closeDrawer();
    navigate("/mart/shops");
  };

  const inform = () => {
    closeDrawer();
    toast.info("Coming sooner...");
  };

  const getTabName = () => (currentTab.startsWith("/mart") ? "mart" : "events");

  const showAccounts = () => {
    onContentClick();
    onSwitchAccounts();
  };

  const viewAdminContacts = () => {
    closeDrawer();
    navigate("/mart/guides/contact");
  };

  const viewGuides = () => {
    closeDrawer();
    navigate("/mart/guides");
  };

  const Content = () => {
    const Common = () => (
      <>
        <button
          className="btn btn-wide btn-secondary"
          onClick={viewAdminContacts}
        >
          Contact Us
          <BsHeadset />
        </button>
        <button
          className="btn btn-wide btn-outline btn-success"
          onClick={viewGuides}
        >
          Guide (Docs)
          <HiOutlineDocumentDuplicate />
        </button>
      </>
    );

    if (currentTab.startsWith("/mart"))
      return (
        <div className="flex flex-col space-y-2">
          <button className="btn btn-primary" onClick={navigateToProducts}>
            Products
            <RiShoppingCart2Line className="inline-block ml-2" />
          </button>
          <button className="btn btn-warning" onClick={navigateToShops}>
            Shops
            <RiStore2Line className="inline-block ml-2" />
          </button>
          <Common />
        </div>
      );

    return (
      <div className="flex flex-col space-y-2">
        <button className="btn btn-primary" onClick={inform}>
          Events
          <RiCalendarEventLine className="inline-block ml-2" />
        </button>
        <button className="btn btn-secondary" onClick={inform}>
          Posters
          <FaImages className="inline-block ml-2" />
        </button>
        <Common />
      </div>
    );
  };

  return (
    <div className="p-4">
      <div className="flex items-center justify-center mb-6">
        <img src={logo} alt="logo" width={30} height={30} className="mr-2" />
        <p className="text-2xl font-semibold text-white-800">{getTabName()}</p>
      </div>

      <section className="h-80">
        <Content />
      </section>

      <div className="flex flex-col space-y-2">
        {activeAccount ? (
          <Account
            image={activeAccount.data.image}
            name={activeAccount.data.name}
            onClick={showAccounts}
            selected
            subTitle="Shop Account"
          />
        ) : (
          <Account
            image={user?.avatar}
            name={user?.name || ""}
            onClick={showAccounts}
            selected
            subTitle="User Account"
          />
        )}
        <button className="btn btn-outline btn-error" onClick={closeDrawer}>
          Close <RiCloseLine className="inline-block ml-2" />
        </button>
      </div>
    </div>
  );
};

export default DrawerContent;
