import { useEffect } from "react";

export const getSearchParam = (name: string) => {
  const params = new URLSearchParams(location.search);

  return params.get(name);
};

const useSearchParams = () => {
  useEffect(() => {
    const url = getSearchParam("q");

    if (url) window.location.href = url;
  }, []);

  return {};
};

export default useSearchParams;
