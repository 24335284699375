import { useNavigate } from "react-router-dom";

import useOrder, { Order } from "../../hooks/useOrder";

const OrderCard = (order: Order) => {
  const { setOrder } = useOrder();
  const navigate = useNavigate();

  const { _id, buyer, canceled, products, message, status, timestamp } = order;

  const viewOrderDetails = () => {
    setOrder(order);
    navigate(_id);
  };

  return (
    <article
      className="card bg-base-100 shadow-md cursor-pointer my-4 flex flex-col sm:flex-row justify-between items-center max-w-screen-md mx-auto"
      onClick={viewOrderDetails}
    >
      <div className="flex items-center space-x-4 w-full sm:w-auto">
        <figure>
          <img
            src={buyer.avatar}
            alt={buyer.name}
            className="w-24 h-24 object-cover"
          />
        </figure>
        <div className="card-body">
          <h2 className="card-title">{buyer.name}</h2>
          {message && <p className="text-sm">"{message}"</p>}
          <p>Status: {canceled ? "CANCELED" : status.label}</p>
          <p>Timestamp: {new Date(timestamp).toLocaleString()}</p>
          <p>Products: {Object.keys(products).length}</p>
        </div>
      </div>
    </article>
  );
};

export default OrderCard;
