import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { processResponse } from "../services/client";
import { User } from "../hooks/useUser";
import { useUser } from "../hooks";
import cache from "../services/cache";
import service from "../services/users";

export const whatsAppCacheKey = "WhatsAppNumberCheck";

const WhatsAppNumberInput = () => {
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [number, setNumber] = useState("");
  const { user } = useUser();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getWhatsAppNumber = async () => {
      if (user) {
        setLoading(true);
        const res = processResponse(await service.getUser(user._id));
        setLoading(false);
        if (res.ok) {
          setNumber((res.data as User).otherAccounts?.whatsapp || "");
          cache.store(whatsAppCacheKey, { hasWhatsAppNumber: true });
        }
      }
    };

    getWhatsAppNumber();
  }, [user?._id]);

  const handleSaveWhatsapp = async () => {
    if (whatsappNumber.startsWith("254") || whatsappNumber.startsWith("+254")) {
      const res = processResponse(
        await service.updateUserInfo({
          otherAccounts: { ...user?.otherAccounts, whatsapp: whatsappNumber },
        })
      );

      res.ok
        ? toast.success(`WhatsApp number ${whatsappNumber} saved successfully!`)
        : toast.error("WhatsApp number update failed");
    } else toast.error("WhatsApp number must start with '254'");
  };

  if (loading) return null;

  if (!user || number || cache.get(whatsAppCacheKey)) return null;

  return (
    <div className="flex flex-col md:flex-row w-full justify-center items-center px-3 mt-6 mb-2 mx-auto space-y-3 md:space-y-0 md:space-x-4">
      <p className="text-center md:text-left text-lg font-semibold">
        Add WhatsApp number?
      </p>
      <div className="flex flex-col md:flex-row items-center space-y-3 md:space-y-0 md:space-x-3">
        <input
          type="text"
          className="input input-bordered w-full md:w-auto"
          placeholder="Enter WhatsApp number (e.g. 2547XXXXXXX)"
          value={whatsappNumber}
          onChange={(e) => setWhatsappNumber(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleSaveWhatsapp}>
          {loading ? "Saving" : "Save"}
        </button>
      </div>
    </div>
  );
};

export default WhatsAppNumberInput;
