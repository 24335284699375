import { useEffect, useState } from "react";
import { Avatar } from "stream-chat-react";
import { FaWhatsapp } from "react-icons/fa";
import { CgSpinner } from "react-icons/cg";

import { User } from "../hooks/useUser";
import { useUsers } from "../hooks";
import usersService from "../services/users";

const AdminsContacts = () => {
  const [admins, setAdmins] = useState<User[]>([]);
  const [loadingAdmins, setLoadingAdmins] = useState(false);
  const { users } = useUsers();

  useEffect(() => {
    const getAdmins = async (): Promise<User[]> => {
      if (admins.length) return users.filter((user) => user.isAdmin);

      const res = await usersService.getAdmins();

      return res.ok ? (res.data as User[]) : [];
    };

    const initAdmins = async () => {
      if (admins.length) return;

      setLoadingAdmins(true);
      const result = await getAdmins();
      setAdmins(result);
      setLoadingAdmins(false);
    };

    initAdmins();
  }, [admins, users]);

  if (loadingAdmins)
    return (
      <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
        <CgSpinner className="animate-spin text-gray-500 text-6xl" />
      </div>
    );

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-indigo-100 py-10 px-4 rounded-sm">
      <h1 className="text-3xl font-semibold text-center text-gray-800 mb-8">
        Admin Contacts
      </h1>
      <p className="text-center">
        We'll contact our business network, just for you
      </p>
      <div className="space-y-6 max-w-lg mx-auto">
        {admins.map((admin, index) => (
          <div
            key={index}
            className="bg-white shadow-md rounded-xl p-6 flex items-center space-x-4 hover:shadow-lg transition-shadow duration-300"
          >
            <Avatar image={admin.avatar} name={admin.name} size={60} />
            <div className="flex-grow">
              <h2 className="text-xl font-bold text-gray-800">{admin.name}</h2>
              <p className="text-gray-600">{admin.email}</p>
              <a
                href={`https://wa.me/${admin?.otherAccounts?.whatsapp?.replace(
                  /\D/g,
                  ""
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-4 inline-flex items-center px-5 py-2 bg-green-500 text-white font-semibold rounded-full hover:bg-green-600 transition-colors duration-300"
              >
                <FaWhatsapp className="mr-2" />
                Contact on WhatsApp
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminsContacts;
