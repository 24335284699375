import { Product } from "../../hooks/useProducts";
import Grid from "../Grid";
import ProductCard from "./Card";

interface Props {
  products: Product[];
}

const HorizontalProductList = ({ products }: Props) => {
  return (
    <Grid>
      {products.map((product) => (
        <ProductCard key={product._id} {...product} />
      ))}
    </Grid>
  );
};

export default HorizontalProductList;
