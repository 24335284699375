import { NotificationActivity } from "getstream";
import { FaProductHunt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { ActivityActor } from "../products/Activity";
import { randomImage } from "../../utils/funcs";
import Avatar from "./Avatar";
import NotificationBlock from "./NotificationBlock";

interface Props {
  groupActivity: NotificationActivity;
}

const ProductNotification = ({
  groupActivity: { activities, activity_count },
}: Props) => {
  const navigate = useNavigate();

  const firstActivityProductId = (
    activities[0] as unknown as { productId: string }
  ).productId;

  const viewProductDetails = (productId: string) =>
    navigate(`/mart/products/${productId}`);

  const handleClick = () => {
    if (activity_count === 1) viewProductDetails(firstActivityProductId);
  };

  return (
    <NotificationBlock onClick={handleClick}>
      <FaProductHunt size={20} />
      <div className="right">
        {activities.map((activity, index) => {
          const actor = activity.actor as unknown as ActivityActor;

          return (
            <Avatar
              onClick={() =>
                viewProductDetails(
                  (activity as unknown as { productId: string }).productId
                )
              }
              src={actor.data.avatar || randomImage}
              key={index}
            />
          );
        })}

        <p className="actors__text">
          <p
            className="inline-flex font-bold cursor-pointer"
            onClick={() => viewProductDetails(firstActivityProductId)}
          >
            {(activities[0] as unknown as { shopName: string }).shopName}
          </p>{" "}
          {activities.length > 1 &&
            `and ${activities.length - 1} other${
              activities.length - 1 > 1 ? "s" : ""
            } `}
          posted a new product
        </p>
      </div>
    </NotificationBlock>
  );
};

export default ProductNotification;
