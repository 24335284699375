import { NotificationActivity } from "getstream";
import { FaCartArrowDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { ActivityActor, ActivityObject } from "../products/Activity";
import accounts from "../../services/accounts";
import Avatar from "./Avatar";
import funcs, { randomImage } from "../../utils/funcs";
import NotificationBlock from "./NotificationBlock";

interface OrderActivity extends ActivityObject {
  orderId: string;
  message: string;
  products: { [productId: string]: number };
}

interface Props {
  groupActivity: NotificationActivity;
}

const OrderNotification = ({ groupActivity }: Props) => {
  const navigate = useNavigate();

  const { activities, activity_count, actor_count } = groupActivity;

  const actorHasOrders = actor_count === 1 && activity_count > 1;

  const viewOrder = (orderId: string) =>
    navigate(
      `/mart/shops/${funcs.convertShopNameToUrl(
        accounts.getAcitveAccount()?.data.name || "unknown"
      )}/orders/${orderId}`
    );

  return (
    <NotificationBlock>
      <FaCartArrowDown size={20} />
      <div className="right">
        {activities.map((activity, index) => {
          const actor = activity.actor as unknown as ActivityActor;
          const orderActivity = activity as unknown as OrderActivity;

          return (
            <Avatar
              onClick={() => viewOrder(orderActivity.orderId || "")}
              src={actor.data.avatar || randomImage}
              key={index}
            />
          );
        })}

        <p className="actors__text">
          <p
            className="inline-flex font-bold cursor-pointer"
            onClick={() =>
              viewOrder(
                (activities[0] as unknown as OrderActivity).orderId || ""
              )
            }
          >
            {(activities[0].actor as unknown as ActivityActor).data.name}
          </p>{" "}
          {activity_count &&
            actor_count > 1 &&
            `and ${activities.length - 1} other${
              activities.length - 1 > 1 ? "s" : ""
            } `}
          {activity_count && actor_count > 1 ? "have" : "has"} just placed{" "}
          {actorHasOrders ? "" : "an"}
          order{actorHasOrders ? "s" : ""} with your shop
        </p>
      </div>
    </NotificationBlock>
  );
};

export default OrderNotification;
