import { useEffect, useState } from "react";
import { useStreamContext } from "react-activity-feed";
import { useNavigate } from "react-router-dom";

import { funcs } from "../../utils";
import { randomImage } from "../../utils/funcs";
import { FeedUser } from "../../hooks/useFeedUser";
import { Shop } from "../../hooks/useShop";
import { useFeedUser, useProducts } from "../../hooks";
import GroupAvatar from "../GroupAvatar";
import SubscribeButton from "./SubscribeButton";

const Profile = ({ _id, image, name, location }: Shop) => {
  const navigate = useNavigate();
  const { products } = useProducts();
  const { client } = useStreamContext();
  const [feedUser, setFeedUser] = useState<FeedUser>();

  useEffect(() => {
    if (!_id) return;

    const getUser = async () => {
      try {
        const feedUser = await client
          ?.user(_id)
          .get({ with_follow_counts: true });

        if (feedUser?.full) setFeedUser(feedUser.full);
      } catch (error) {
        console.error(`Error ${error}`);
      }
    };

    getUser();
  }, [_id]);

  const shopProducts = products.filter((p) => p.shop?._id === _id);

  const navigateToShop = () =>
    navigate(`/mart/shops/${funcs.convertShopNameToUrl(name)}`);

  return (
    <article
      className="card w-full glass cursor-pointer"
      onClick={navigateToShop}
    >
      <figure className="relative h-48 w-full">
        <img
          src={image || randomImage}
          alt={name}
          className="absolute inset-0 w-full h-full object-cover"
        />
      </figure>
      <section className="card-body py-2 px-7">
        <h2 className="card-title line-clamp-2">{name}</h2>
        <p className="line-clamp-1 text-sm">- {location}</p>
        <GroupAvatar images={shopProducts.map((p) => p.images[0])} />
      </section>
      <div className="px-7 pb-5 w-full">
        <SubscribeButton shopId={_id} count={feedUser?.following_count} />
      </div>
    </article>
  );
};

export default Profile;
