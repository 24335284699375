import { ServiceInfo } from "../components/services/NewServiceForm";
import { User } from "../hooks/useUser";
import client, { getFailedResponse, processResponse } from "./client";

export const endpoint = "/services";

export interface Service extends NewService {
  _id: string;
  author: User;
  timestamp: number;
}

interface NewService extends ServiceInfo {
  images: string[];
}

const create = async (service: NewService) => {
  try {
    return processResponse(await client.post(endpoint, service));
  } catch (error) {
    return getFailedResponse(error);
  }
};

const getAll = async () => {
  try {
    return processResponse(await client.get(endpoint));
  } catch (error) {
    return getFailedResponse(error);
  }
};

export default { create, getAll };
