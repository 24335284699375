import { Avatar } from "react-activity-feed";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Calendar from "../../assets/Calendar";
import EditProfileButton from "./EditProfileBtn";
import Mail from "../../assets/Mail";
import useUser, { User } from "../../hooks/useUser";

interface Props {
  user: User | undefined;
}

const ProfileBio = ({ user }: Props) => {
  const { user: currentUser } = useUser();
  const navigate = useNavigate();

  const actions = [
    {
      Icon: Mail,
      id: "message",
      onClick: startDM,
    },
  ];

  const isLoggedInUserProfile = user?._id === currentUser?._id;

  const joinedDate = format(
    new Date(user?.timestamp || Date.now()),
    "MMMM RRRR"
  );

  function startDM() {
    if (user) navigate(`/messages?${user._id}`);
  }

  return (
    <Container>
      <div className="top">
        <Avatar image={user?.avatar} circle size={100} />
        {!isLoggedInUserProfile ? (
          <div className="actions">
            {actions.map((action) => (
              <button
                className="action-btn"
                key={action.id}
                onClick={action.onClick}
              >
                <action.Icon color="white" size={21} />
              </button>
            ))}
          </div>
        ) : (
          <div className="actions">
            <button className="btn btn-sm btn-success rounded-xl mx-2">
              Orders
            </button>
            <EditProfileButton />
          </div>
        )}
      </div>
      <div className="details">
        <span className="user__name">{user?.name}</span>
        <span className="user__id">{user?.email}</span>
        <span className="user__bio" />
        <div className="user__joined">
          <Calendar color="#777" size={20} />
          <span className="user__joined--text">Joined {joinedDate}</span>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
  position: relative;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: calc(var(--profile-image-size) / -1.5);

    .rounded-full {
      border: 4px solid #888; /* Adds a border to the image */
      padding: 4px; /* Adds some padding around the border */
      border-radius: 50%; /* Ensures the image stays round */
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      .action-btn {
        border: 1px solid #777;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .details {
    color: #888;
    margin-top: 20px;

    .user {
      &__name {
        color: white;
        font-weight: bold;
        margin-right: 3px;
      }

      &__id {
        margin-top: 2px;
        font-size: 15px;
      }

      &__bio {
        color: white;
        margin-top: 10px;
        a {
          color: var(--theme-color);
          text-decoration: none;
        }
      }

      &__joined {
        display: flex;
        align-items: center;
        margin-top: 15px;
        font-size: 15px;

        &--text {
          margin-left: 5px;
        }
      }
    }
  }
`;

export default ProfileBio;
