import { AdminsContacts } from "../components";

const ContactUsPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-3xl bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-semibold text-center text-gray-800 mb-6">
          Contact Us
        </h1>
        <p className="text-center text-gray-600 mb-8">
          We’re here to help. Whether you're a seller or a buyer, feel free to
          reach out to us.
        </p>
        <AdminsContacts />
      </div>
    </div>
  );
};

export default ContactUsPage;
