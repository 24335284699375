import { useState } from "react";

interface Props {
  image: string | undefined;
  name: string;
  subTitle: string;
  selected: boolean;
  onClick: () => void;
}

const Account = ({ image, name, subTitle, selected, onClick }: Props) => {
  return (
    <div
      className={`relative flex items-center py-2 pl-4 cursor-pointer transition-colors duration-300 rounded-lg ${
        selected ? "inherit text-white" : "hover:bg-base-200"
      }`}
      onClick={onClick}
    >
      <img
        src={image || "https://via.placeholder.com/150"}
        alt={name}
        className="w-12 h-12 rounded-full object-cover mr-2"
      />
      <div className="flex-1">
        <p className="text-lg font-semibold truncate">{name}</p>{" "}
        <p className="text-sm text-gray-500 truncate">{subTitle}</p>{" "}
      </div>
      {selected && (
        <span className="absolute right-4 text-xl text-success">✔️</span>
      )}
    </div>
  );
};

export default Account;
