import { useSubscription, useUser } from "../../hooks";

interface Props {
  shopId: string;
  count?: number;
}

const SubscribeButton = ({ count = 0, shopId }: Props) => {
  const { isSubscribing, toggleSubscription } = useSubscription({ shopId });
  const { user } = useUser();

  const handleToggle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();

    toggleSubscription();
  };

  const getLabel = () => {
    if (!user) return "Login to Subscribe";

    return isSubscribing ? "subscribed" : "subscribe";
  };

  return (
    <button
      onClick={handleToggle}
      className={`btn ${
        isSubscribing ? "btn-success" : "btn-primary"
      } btn-outline rounded-full w-full ${!user && "btn-disabled"}`}
    >
      {getLabel()} {count ? `(${count})` : ""}
    </button>
  );
};

export default SubscribeButton;
