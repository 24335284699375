import { useEffect, useState } from "react";

import { useUser } from "../../hooks";
import Account from "./Account";
import accounts, { ShopAccount } from "../../services/accounts";

interface Props {
  onDoneAccountSwitch: () => void;
}

const Accounts = ({ onDoneAccountSwitch }: Props) => {
  const [shopAccounts, setShopAccounts] = useState<ShopAccount[]>([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const { user } = useUser();

  useEffect(() => {
    const { shops } = accounts.getAccounts();

    setShopAccounts(shops);
    shops.forEach((s) => {
      if (s.active) setSelectedAccount(s.data.name);
    });
  }, []);

  const handleAccountSelection = (id: string) => {
    setSelectedAccount(id);

    user?._id !== id
      ? accounts.activateAccountById(id)
      : accounts.activateAccountById("");

    onDoneAccountSwitch();
  };

  return (
    <div>
      <p className="text-center">
        Your personal account is separate from your shops' account. You need to
        switch between them
      </p>
      <Account
        image={user?.avatar}
        name={user?.name || ""}
        subTitle="User Account"
        selected={selectedAccount ? selectedAccount === user?._id : true}
        onClick={() => handleAccountSelection(user?._id || "")}
      />
      {shopAccounts.map((shop, index) => (
        <Account
          key={index}
          image={shop.data.image}
          onClick={() => handleAccountSelection(shop.id)}
          selected={selectedAccount === shop.id}
          name={shop.data.name}
          subTitle="Shop Account"
        />
      ))}
    </div>
  );
};

export default Accounts;
