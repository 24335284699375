import { useEffect, useState } from "react";

import accounts from "../services/accounts";
import useUser from "./useUser";

const useActiveAccount = () => {
  const [activeAccountId, setActiveAccountId] = useState("");
  const { user } = useUser();

  useEffect(() => {
    const active = accounts.getAcitveAccount();
    setActiveAccountId(active?.id || user?._id || "");
  }, []);

  return { activeAccountId };
};

export default useActiveAccount;
