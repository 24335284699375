import { useContext, useEffect } from "react";

import { endpoint } from "../services/posters";
import { PostersContext } from "../contexts";
import useData from "./useData";

export interface Poster {
  image: string;
}

const usePosters = () => {
  const { data: posters, ...rest } = useData<Poster>(endpoint);
  const { setPosters, posters: retrieved } = useContext(PostersContext);

  useEffect(() => {
    try {
      if (!retrieved.length) setPosters(posters?.length ? posters : []);
    } catch (error) {}
  }, []);

  const addPoster = (poster: Poster) => setPosters([poster, ...retrieved]);

  return { addPoster, posters, ...rest };
};

export default usePosters;
