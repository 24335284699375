import { useEffect, useState } from "react";
import { GetUnreadCountAPIResponse } from "stream-chat";
import { useChatContext } from "stream-chat-react";

const useUnreadChats = () => {
  const { client } = useChatContext();
  const [countResponse, setCountResponse] =
    useState<GetUnreadCountAPIResponse>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!client) return;

    fetchUnreadCount();

    const handleNewMessage = () => fetchUnreadCount();

    const handleReadMessage = () => fetchUnreadCount();

    client.on("message.new", handleNewMessage);
    client.on("message.read", handleReadMessage);

    return () => {
      client.off("message.new", handleNewMessage);
      client.off("message.read", handleReadMessage);
    };
  }, []);

  async function fetchUnreadCount() {
    try {
      if (client) {
        if (loading) return;

        setLoading(true);
        const count = await client.getUnreadCount();
        setLoading(false);
        setCountResponse(count);
      }
    } catch (error) {}
  }

  return { countResponse, unreadCount: countResponse?.total_unread_count || 0 };
};

export default useUnreadChats;
