import { FaCartArrowDown, FaUser } from "react-icons/fa";

import { User } from "../../hooks/useUser";

interface Props {
  shopImage: string;
  productsCount: number;
  seller: User;
  subscribers: number;
}

const Stats = ({ productsCount, seller, shopImage, subscribers }: Props) => {
  const { avatar } = seller;

  return (
    <section className="stats shadow">
      <article className="stat">
        <div className="stat-figure text-primary  hidden sm:block">
          <FaCartArrowDown
            className="inline-block w-8 h-8 stroke-current"
            aria-hidden="true"
          />
        </div>
        <h2 className="stat-title">Products</h2>
        <p className="stat-value text-primary text-center">{productsCount}</p>
      </article>

      <article className="stat">
        <div className="stat-figure text-secondary hidden sm:block">
          <FaUser size={25} />
        </div>
        <h2 className="stat-title">Subscriber{subscribers === 1 ? "" : "s"}</h2>
        <p className="stat-value text-secondary text-center">{subscribers}</p>
      </article>

      <article className="stat">
        <div className="stat-figure text-secondary hidden sm:block">
          <div className="avatar online">
            <div className="w-16 rounded-full">
              <img src={avatar || shopImage} alt="Avatar" />
            </div>
          </div>
        </div>
        <p className="stat-value">86%</p>
        <h2 className="stat-title">Tasks done</h2>
        <p className="stat-desc text-secondary hidden sm:block">
          31 tasks remaining
        </p>
      </article>
    </section>
  );
};

export default Stats;
