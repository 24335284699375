import { useEffect, useState } from "react";

import { funcs } from "../utils";

const link = funcs.removeLeadingSlash(location.pathname);

export function getWhatsAppLink(phoneNumber: string | number) {
  return `https://api.whatsapp.com/send?phone=${phoneNumber}&text=I saw your listing at ${window.location.href} can we chat further?`;
}

const useWhatsAppRedirect = (
  phoneNumber: string | number | undefined,
  imageUrl?: string
) => {
  const [url, setUrl] = useState("");

  const getUrl = () => {
    const baseUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${window.location.href}`;

    return imageUrl ? `${baseUrl}&image=${imageUrl} => ` : baseUrl;
  };

  useEffect(() => {
    if (phoneNumber) setUrl(getUrl());
  }, [phoneNumber, link]);

  return { url };
};

export default useWhatsAppRedirect;
