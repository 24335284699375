import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineLogin } from "react-icons/ai";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { FaUser } from "react-icons/fa";
import { toast } from "react-toastify";

import { events, logEvent } from "../../db/analytics";
import { googleAuth } from "../../services/auth";
import { useUser } from "../../hooks";
import accounts from "../../services/accounts";
import funcs, { randomImage } from "../../utils/funcs";

interface Props {
  onLoginWithForms: (state: boolean) => void;
  onLogOut: () => void;
  onSwitchAccounts: () => void;
}

const UserButton = ({
  onLoginWithForms,
  onLogOut,
  onSwitchAccounts,
}: Props) => {
  const { googleUser, user } = useUser();
  const [isShopAccount, setIsShopAccount] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsShopAccount(!!accounts.getAcitveAccount()?.id);
  }, []);

  const activeAccount = accounts.getAcitveAccount();

  const logInWithRedirect = async () => {
    await signInWithPopup(googleAuth, new GoogleAuthProvider());
    logEvent(events.userInteraction.LOGIN);
    window.location.reload();
  };

  const navigateToProfile = () => {
    if (user?._id) navigate(`/mart/profile/${user?._id}`);
    else toast.info("Loading your profile");
  };

  const navigateToShopPage = () => {
    const shopName = accounts.getAcitveAccount()?.data?.name;
    if (shopName)
      navigate(`/mart/shops/${funcs.convertShopNameToUrl(shopName)}`);
  };

  const Avatar = () => {
    if (!user) return <span className="loading loading-ring loading-lg" />;

    return user.avatar || activeAccount ? (
      <div className="w-9 h-9 rounded-full flex items-center justify-center bg-gray-200">
        <img
          alt={googleUser?.displayName || "avatar"}
          src={
            activeAccount?.data.image ||
            user.avatar ||
            googleUser?.photoURL ||
            randomImage
          }
          className="rounded-full w-full h-full"
        />
      </div>
    ) : (
      <FaUser />
    );
  };

  return (
    <div className="dropdown dropdown-end">
      {user ? (
        <div
          tabIndex={0}
          role="button"
          className="btn btn-ghost btn-circle avatar"
        >
          <Avatar />
        </div>
      ) : (
        <button className="btn btn-sm btn-secondary">
          <AiOutlineLogin /> Login
        </button>
      )}
      <ul
        tabIndex={0}
        className="menu menu-sm dropdown-content mt-3 z-[100] p-2 shadow bg-base-100 rounded-box w-52"
      >
        {user ? (
          <>
            <li onClick={navigateToProfile}>
              <a className="text-lg">Profile</a>
            </li>
            {isShopAccount && (
              <li onClick={navigateToShopPage}>
                <a className="text-lg">View Shop Account</a>
              </li>
            )}
            <li onClick={onSwitchAccounts}>
              <a className="text-lg">Switch Account</a>
            </li>
            <li>
              <a className="text-lg" onClick={onLogOut}>
                Logout
              </a>
            </li>
          </>
        ) : (
          <>
            <li className="text-lg" onClick={logInWithRedirect}>
              <p>Login w/ Google</p>
            </li>
            <li className="text-lg" onClick={() => onLoginWithForms(true)}>
              <p>Login w/ Forms</p>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default UserButton;
