import React from "react";

const EmptyNotificationsPlaceholder: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full py-10">
      <div className="w-16 h-16 mb-4 text-primary">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-full h-full"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 16h-1v-4h-1m0-4h.01M21 12a9 9 0 11-9-9 9 9 0 019 9z"
          />
        </svg>
      </div>
      <h2 className="text-lg font-semibold text-gray-700">
        No Notifications Yet
      </h2>
      <p className="mt-2 text-sm text-gray-500">
        You haven’t received any notifications yet.
      </p>
    </div>
  );
};

export default EmptyNotificationsPlaceholder;
