import { useEffect, useState } from "react";
import { Gallery, UserBar } from "react-activity-feed";
import { BsPlus, BsX } from "react-icons/bs";

import { events, logEvent } from "../db/analytics";
import { ProductType } from "../hooks/useProducts";
import { ProductTypesList } from "../components";
import { Service } from "../services/service";
import { useUser } from "../hooks";
import NewServiceForm from "../components/services/NewServiceForm";
import service from "../services/service";

const servicesTypes = [
  { _id: "", label: "All" },
  { _id: "1", label: "Barber" },
  { _id: "2", label: "Salon" },
  { _id: "3", label: "Photography" },
  { _id: "4", label: "Online Writing" },
];

const ServicesPage = () => {
  const [selectedService, setSelectedService] = useState<ProductType>();
  const [isAddingService, setIsAddingService] = useState(false);
  const [services, setServices] = useState<Service[]>([]);
  const [expandedService, setExpandedService] = useState<number | null>(null);
  const { user } = useUser();

  useEffect(() => {
    async function getAllServices() {
      try {
        const res = await service.getAll();
        if (res.ok) setServices(res.data as Service[]);
      } catch (error) {}
    }

    getAllServices();

    logEvent(events.general.PAGE_VIEW, {
      userId: user?._id,
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  const toggleReadMore = (index: number) => {
    setExpandedService(expandedService === index ? null : index);
  };

  return (
    <div className="max-w-5xl mx-auto p-4">
      <div className="flex flex-col md:flex-row w-full justify-center items-center px-3 my-6 mx-auto space-y-3 md:space-y-0 md:space-x-4">
        <p className="text-center md:text-left text-lg font-semibold">
          What service do you offer?
        </p>
        <button
          className="btn btn-sm btn-primary flex items-center space-x-2"
          onClick={() => setIsAddingService(!isAddingService)}
        >
          {isAddingService ? <BsX /> : <BsPlus />}
          <span>{isAddingService ? "Cancel" : "Add Service"}</span>
        </button>
      </div>

      {isAddingService && (
        <div className="shadow-md rounded-lg px-6 py-4 mb-6 bg-base-100">
          <NewServiceForm onDone={() => setIsAddingService(false)} />
        </div>
      )}

      <div className="flex justify-center items-center my-5">
        <ProductTypesList
          badges={servicesTypes}
          onTypeSelect={setSelectedService}
          selectedType={selectedService}
        />
      </div>

      <div className="space-y-6">
        {services.map((service, index) => {
          const { author, images, description, timestamp } = service;
          const isExpanded = expandedService === index;
          const shouldShowReadMore = (description?.length || 0) > 120;

          return (
            <div
              key={index}
              className="bg-gray-100 shadow-lg rounded-lg p-4 max-w-[400px] w-full mx-auto"
            >
              <UserBar
                follow
                username={author.name}
                avatar={author.avatar}
                timestamp={timestamp}
                className="mb-3"
              />
              <p
                className={`mb-2 text-sm text-base-100 ${
                  !isExpanded ? "line-clamp-3" : ""
                }`}
              >
                {description}
              </p>
              {shouldShowReadMore && (
                <button
                  className="text-primary hover:underline text-sm"
                  onClick={() => toggleReadMore(index)}
                >
                  {isExpanded ? "Show less" : "Read more"}
                </button>
              )}
              <Gallery images={images} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ServicesPage;
