import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { LoginForm, Modal, RegisterForm } from "../components";

const AuthPage = () => {
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const navigate = useNavigate();

  const switchToSignUp = () => {
    setShowLoginForm(false);
    setShowRegisterForm(true);
  };

  const switchToSignIn = () => {
    setShowLoginForm(true);
    setShowRegisterForm(false);
  };

  const handleModalClose = () => navigate(-1);

  return (
    <div>
      <Modal
        content={<LoginForm onSignUpRequest={switchToSignUp} />}
        isOpen={showLoginForm}
        onClose={handleModalClose}
        title=""
      />
      <Modal
        content={<RegisterForm onLoginRequest={switchToSignIn} />}
        isOpen={showRegisterForm}
        onClose={handleModalClose}
        title=""
      />
    </div>
  );
};

export default AuthPage;
