import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";

import { Input, ShopSelector } from "..";
import { Shop } from "../../hooks/useShop";
import { useShops, useUser } from "../../hooks";

interface Props {
  onDoneShopSelect: () => void;
  onShopSelect: (shopId: string) => void;
  selectedShop: string;
}

const ShopSelectors = ({
  onDoneShopSelect,
  onShopSelect,
  selectedShop,
}: Props) => {
  const { shops: allShops, isLoading } = useShops();
  const { user } = useUser();
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const realShops = allShops.filter((s) => {
    if (s._id) return s;
  });

  const shops: Shop[] = user?.isAdmin
    ? realShops
    : realShops.filter((s) => s.author?._id === user?._id);

  const filtered: Shop[] = query
    ? shops.filter((s) => s.name.toLowerCase().includes(query.toLowerCase()))
    : shops;

  if (isLoading)
    return (
      <section className="flex flex-col items-center justify-center h-40">
        <FaSpinner className="animate-spin text-2xl mb-2" />
        <p className="text-center text-gray-500">Loading shops...</p>
      </section>
    );

  if (!Boolean(filtered.length))
    return (
      <div>
        <p className="text-center mb-1">You don't have any shops created</p>
        <div className="flex justify-center">
          <button
            className="btn btn-primary"
            onClick={() => navigate("shops/new")}
          >
            Create your shop
          </button>
        </div>
      </div>
    );

  return (
    <section className="space-y-3">
      <Input onChange={setQuery} placeholder="Search Your Shop" value={query} />
      {filtered.map((shop, index) => (
        <ShopSelector
          key={index}
          onClick={() => onShopSelect(shop._id)}
          selected={selectedShop === shop._id}
          onDoubleClick={onDoneShopSelect}
          shop={shop}
          className="cursor-pointer p-2 rounded-lg border hover:bg-gray-100"
        />
      ))}
    </section>
  );
};

export default ShopSelectors;
