import { NotificationActivity } from "getstream";
import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { ActivityActor } from "../products/Activity";
import { randomImage } from "../../utils/funcs";
import Avatar from "./Avatar";
import NotificationBlock from "./NotificationBlock";

interface Props {
  groupActivity: NotificationActivity;
}

const FollowNotification = ({ groupActivity }: Props) => {
  const navigate = useNavigate();

  const activities = groupActivity.activities;

  const viewUserProfile = (actor: ActivityActor) =>
    navigate(`/mart/profile/${(actor as unknown as ActivityActor).id}`);

  return (
    <NotificationBlock>
      <FaUser size={20} />
      <div className="right">
        {activities.map((activity, index) => {
          const actor = activity.actor as unknown as ActivityActor;

          return (
            <Avatar
              onClick={() => viewUserProfile(actor)}
              src={actor.data.avatar || randomImage}
              key={index}
            />
          );
        })}

        <p className="actors__text">
          <p
            className="inline-flex font-bold cursor-pointer"
            onClick={() =>
              viewUserProfile(activities[0].actor as unknown as ActivityActor)
            }
          >
            {(activities[0].actor as unknown as ActivityActor).data.name}
          </p>{" "}
          {activities.length > 1 &&
            `and ${activities.length - 1} other${
              activities.length - 1 > 1 ? "s" : ""
            } `}
          subscribed to your shop
        </p>
      </div>
    </NotificationBlock>
  );
};

export default FollowNotification;
