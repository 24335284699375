import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import {
  ErrorMessage,
  Form,
  FormField,
  FormTextAreaField,
  SubmitButton,
  FormCheckBoxField,
} from "../form";
import { emptyType } from "../../utils/empty";
import { NewProduct } from "../../services/products";
import { ProductType } from "./TypesList";
import {
  useImages,
  // useNotifications,
  // useProductActivity,
  useProducts,
  useUser,
} from "../../hooks";
import { getSearchParam } from "../../hooks/useSearchParams";
import { Product } from "../../hooks/useProducts";
import { UploadPage } from "../../pages";
import ImageInputList from "../common/ImageInputList";
import service from "../../services/products";
import ShopTypesSelector from "../shop/TypesSelector";
import storage from "../../db/image";

export const productSchema = Yup.object().shape({
  name: Yup.string().min(1).max(50).required(),
  price: Yup.number().min(1).max(1000000).required(),
  description: Yup.string(),
  isNegotiable: Yup.boolean(),
});

export type ProductInfo = Yup.InferType<typeof productSchema>;

const MAX_IMAGE_INPUT = 7;

const ProductForm = () => {
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { images, imagesCount, removeAllImages } = useImages(MAX_IMAGE_INPUT);
  const [selectedType, setSelectedType] = useState<ProductType>(emptyType);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { user } = useUser();
  const { addProduct } = useProducts();
  const [shopId, setShopId] = useState("");

  useEffect(() => {
    const shopId = getSearchParam("shopId");
    if (shopId) setShopId(shopId);
  }, []);

  const makeProductFrom = async (
    info: ProductInfo
  ): Promise<NewProduct | undefined> => {
    const imagesUrl = await storage.saveImages(images);
    if (!imagesUrl.length) {
      setError("Error saving images");
      return;
    }

    if (user && shopId)
      return {
        ...info,
        author: user._id,
        images: imagesUrl,
        shop: shopId,
        type: selectedType._id,
      };
    else await storage.deleteImages(imagesUrl);
  };

  const handleSubmit = async (info: ProductInfo) => {
    if (!shopId)
      return setError("Your shop details aren't known. Refresh app ");
    if (error) setError("");
    if (!imagesCount) return setError("Please select at least an image");
    if (!selectedType._id) return setError("Please select the product type");

    setLoading(true);
    const newProduct = await makeProductFrom(info);
    if (!newProduct) return setLoading(false);

    const res = await service.create(newProduct, setUploadProgress);

    if (!res?.ok) {
      await storage.deleteImages(newProduct.images);
      toast.error("Something failed");
      return setError(res?.problem || "Product couldn't be saved");
    }

    addProduct(res.data as Product);
    toast.info("Product created successfully");
    removeAllImages();
    // await createProductActivity(res.data as Product);

    // await createProductNotificationForShopSubscribers(res.data as Product);
    window.location.reload();
  };

  if (!user) return <Navigate to="/" />;

  if (isLoading)
    return (
      <UploadPage progress={uploadProgress} onDone={() => setLoading(false)} />
    );

  return (
    <Form
      initialValues={{
        name: "",
        price: 0,
        description: "",
        isNegotiable: true,
      }}
      onSubmit={handleSubmit}
      validationSchema={productSchema}
      title="New Product"
    >
      <ImageInputList imagesLimit={MAX_IMAGE_INPUT} />
      <ErrorMessage error={error} visible />
      <ShopTypesSelector
        onTypeSelect={setSelectedType}
        selectedTypes={{ [selectedType._id]: selectedType }}
      />
      <FormField name="name" />
      <FormField name="price" type="number" />
      <FormCheckBoxField name="isNegotiable" label="Is the price negotiable?" />
      <FormTextAreaField name="description" />
      <SubmitButton
        title={isLoading ? "Creating Product..." : "Create Product"}
      />
    </Form>
  );
};

export default ProductForm;
