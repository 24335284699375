import React, { useState } from "react";

import { useProfileUser } from "../hooks";
import ProfileBio from "../components/profile/ProfileBio";
import ProfileHeader from "../components/profile/ProfileHeader";
import ProfileProducts from "../components/profile/ProfileProducts";
import ProfileShops from "../components/profile/ProfileShops";
import styled from "styled-components";
import TabList, { Tab } from "../components/TabList";

type TabName = "Shops" | "Products";

const tabs: Tab<TabName>[] = [
  { tabLabel: "Shops", tabName: "Shops" },
  { tabLabel: "Products", tabName: "Products" },
];

const ProfilePage: React.FC = () => {
  const { profileUser } = useProfileUser();
  const [activeTab, setActiveTab] = useState<TabName>("Products");

  return (
    <Container>
      <ProfileHeader user={profileUser} />
      <main>
        <ProfileBio user={profileUser} />
      </main>

      <TabList<TabName>
        tabs={tabs}
        activeTab={activeTab}
        onTabClick={setActiveTab}
      />

      {activeTab === "Products" ? <ProfileProducts /> : <ProfileShops />}
    </Container>
  );
};

const Container = styled.div`
  --profile-image-size: 100px;
`;

export default ProfilePage;
